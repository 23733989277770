function pluralize(word: string): string
{
	if (!word || typeof word !== 'string' || word.length === 0)
	{
		return word;
	}

	// Regular expressions for different pluralization rules
	const pluralRules = [
		{ regex: /s$/, suffix: 'ses' }, // drug class -> drug classes
		{ regex: /([^aeiou])y$/, suffix: '$1ies' }, // company -> companies
		{ regex: /$/, suffix: 's' } // drug -> drugs
	];

	//If the calling context is already plural, return the word as is
	if (isPlural(word))
	{
		return word;
	}

	for (const rule of pluralRules)
	{
		if (rule.regex.test(word))
		{
			return word.replace(rule.regex, rule.suffix);
		}
	}

	return word;
}

//may need to add more rules but for now handles drug class -> drug classes properly
function isPlural(word: string): boolean
{
	return /s$/.test(word) && !/ss$/.test(word);
}

export default pluralize;
