import { PrecomputedValueSet } from '@/components/Enum/NestedSelectDropdownPC';

import { EnumsState } from '@/redux/enumsSlice';
import store from '@/redux/store';

import pluralize from '../format/pluralize';

const itemsLoaded = (entity_links: any[]): boolean =>
{
	let state = store.getState();
	let enums = state.enums.enums as any;

	//For each item in the monitor, check if the item is loaded
	const loaded = entity_links.every((entity_link: any) =>
	{
		// const typeEnum = enums[pluralize(entity_link.entity_type) as keyof EnumsState] as any[];
		const typeEnumRaw = enums[pluralize(entity_link.entity_type) as keyof EnumsState] as any;
		const typeEnum = Array.isArray(typeEnumRaw) ? typeEnumRaw : (typeEnumRaw as PrecomputedValueSet)?.sortedDistinctValuesWithVariants;

		//If the enum doesn't exist, it's not loaded yet.
		if (!typeEnum)
		{
			// console.log('No enum found for entity type', entity_link.entity_type, pluralize(entity_link.entity_type), enums);
			return false;
		}

		const entity = typeEnum.find((enumItem: any) => enumItem.id === entity_link.entity_id);
		// console.log('Checking if entity is loaded', entity_link, entity);
		return !!entity;
	});

	return loaded;
};

const itemsLoadedById = (monitor_id: string): boolean =>
{
	let state = store.getState();
	const monitor = state.session.monitors.find((monitor: any) => monitor.id === monitor_id);

	if (!monitor)
	{
		console.error('Monitor not found', monitor_id, state.session.monitors);
		return false;
	}

	return itemsLoaded(monitor.entity_links);
};

const waitForItems = async (monitor_id: string): Promise<boolean> =>
{
	while (!itemsLoadedById(monitor_id))
	{
		// console.log('Waiting for items to load for', monitor_id);
		await new Promise(r => setTimeout(r, 100));
	}

	return true;
};

export { itemsLoaded, itemsLoadedById, waitForItems };
