import { v4 as uuidv4 } from 'uuid';

function getUUID(): string
{
	const uuid = uuidv4().replace(/-/g, '');
	const intUUID = BigInt('0x' + uuid);
	return intUUID.toString();
}

export default getUUID;
