import Link from 'next/link';

import formatName from '@/utils/format/formatName';
import formatNumber from '@/utils/format/formatNumber';
import formatURL from '@/utils/format/formatURL';
import getIcon from '@/utils/getIcon';
import { cn } from '@/utils/lib/utils';
import { ExternalLink } from '@/utils/lucide';

interface BadgeProps
{
	value: string | Date | number | string[];
	variant: 'primary' | 'accent' | 'error' | 'indicator' | 'neutral' | 'light' | 'neutral-dark';
	Icon?: any;
	displayRaw?: boolean;
	className?: string;
	iconClassName?: string;
}

const Badge = ({ value, variant, Icon, displayRaw, className, iconClassName }: BadgeProps) =>
{
	let styleString = '';
	let displayString = '';
	let isLink = false;

	//Parse value
	if (typeof value === 'string')
	{
		//https://daringfireball.net/2010/07/improved_regex_for_matching_urls
		const urlRegex =
			/\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/gi;

		//if(value.includes("http"))
		if (urlRegex.test(value))
		{
			displayString = formatURL(value);
			isLink = true;
		}
		else
		{
			displayString = displayRaw ? value : formatName(value);
		}
	}
	else if (typeof value === 'number')
	{
		displayString = formatNumber(value as number);
	}
	else if (value instanceof Date)
	{
		displayString = value.toLocaleDateString();
	}
	else if (Array.isArray(value))
	{
		displayString = value.map(v => formatName(v)).join(', ');
	}

	//Style string by indicator
	switch (variant)
	{
		case 'primary':
			styleString = 'bg-maven-primary-100 text-maven-primary-700' + (isLink ? ' hover:bg-maven-primary-200' : '');
			break;
		case 'accent':
			styleString = 'bg-maven-accent-100 text-maven-accent-700' + (isLink ? ' hover:bg-maven-accent-200' : '');
			break;
		case 'error':
			styleString = 'bg-maven-error-50 text-maven-error-700' + (isLink ? ' hover:bg-maven-error-200' : '');
			break;
		case 'indicator':
			styleString = 'bg-maven-indicator-50 text-maven-indicator-700' + (isLink ? ' hover:bg-maven-indicator-200' : '');
			break;
		case 'light':
			styleString = 'bg-neutral-50 text-neutral-400 px-2 py-0.5' + (isLink ? ' hover:bg-maven-neutral-200' : '');
			break;
		case 'neutral-dark':
			styleString = 'bg-neutral-200 text-neutral-700' + (isLink ? ' hover:bg-maven-neutral-200' : '');
			break;
		case 'neutral':
		default:
			styleString = 'bg-neutral-50 text-neutral-700' + (isLink ? ' hover:bg-maven-neutral-200' : '');
			break;
	}

	//Handle icons
	if (typeof Icon === 'string') Icon = getIcon(Icon);
	if (typeof Icon === 'function') Icon = <Icon />;

	//console.log(Icon);

	if (displayString.trim().length == 0 || displayString.trim() === '-') return <></>;

	if (isLink)
	{
		return (
			<Link
				href={value as string}
				className={cn('flex flex-row items-center text-nowrap font-bold rounded-xl px-4 py-1 gap-2 ', styleString, className)}
				target='_blank'
				rel='noopener noreferrer'
			>
				{!!Icon && <p className={cn('text-icon', iconClassName)}>{Icon}</p>}
				<p className='text-center w-full'>{displayString}</p>
				<ExternalLink className='min-w-6 text-icon-sm' />
			</Link>
		);
	}
	else
	{
		return (
			<div className={cn('flex flex-row items-center text-nowrap font-bold rounded-xl px-4 py-1 gap-2', styleString, className)}>
				{!!Icon && <p className={cn('text-icon-sm', iconClassName)}>{Icon}</p>}
				<p className='text-center w-full'>{displayString}</p>
			</div>
		);
	}
};

export default Badge;
