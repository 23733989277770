function formatURL(url: string)
{
	if (url.includes('www.'))
	{
		url = url.replace('www.', '');
	}

	try
	{
		new URL(url);
	}
	catch (e)
	{
		return url;
	}

	return new URL(url).host;
}

export default formatURL;

function formatURLWithPath(url: string): string
{
	const urlObj = new URL(url);
	let result = urlObj.hostname + urlObj.pathname;
	result.replace('www.', '');
	if (result.endsWith('/'))
	{
		result = result.slice(0, -1);
	}
	return result;
}

export { formatURLWithPath };
