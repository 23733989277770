import { Landmark, Rows3 } from '@/utils/lucide';
import { FileText } from '@/utils/lucide';
import { Book } from '@/utils/lucide';
import { BookOpen } from '@/utils/lucide';
import { BookmarkPlus } from '@/utils/lucide';
import { PieChart } from '@/utils/lucide';
import { Check } from '@/utils/lucide';
import { CheckCircle } from '@/utils/lucide';
import { Building2 } from '@/utils/lucide';
import { Database } from '@/utils/lucide';
import { BarChart } from '@/utils/lucide';
import { Calendar } from '@/utils/lucide';
import { Monitor } from '@/utils/lucide';
import { CircleDot } from '@/utils/lucide';
import { Edit } from '@/utils/lucide';
import { Search } from '@/utils/lucide';
import { Flag } from '@/utils/lucide';
import { FileSpreadsheet } from '@/utils/lucide';
import { Users } from '@/utils/lucide';
import { GraduationCap } from '@/utils/lucide';
import { Lightbulb } from '@/utils/lucide';
import { Mail } from '@/utils/lucide';
import { Map } from '@/utils/lucide';
import { FlaskConical } from '@/utils/lucide';
import { Activity } from '@/utils/lucide';
import { Newspaper } from '@/utils/lucide';
import { UsersRound } from '@/utils/lucide';
import { FilePlus } from '@/utils/lucide';
import { Pill } from '@/utils/lucide';
import { Archive } from '@/utils/lucide';
import { Quote } from '@/utils/lucide';
import { ClipboardList } from '@/utils/lucide';
import { Signal } from '@/utils/lucide';
import { Tag } from '@/utils/lucide';
import { Trophy } from '@/utils/lucide';
import { Image } from '@/utils/lucide';
import { Target } from '@/utils/lucide';
import { EyeOff } from '@/utils/lucide';
import { Globe } from '@/utils/lucide';
import { Hospital } from '@/utils/lucide';
import { List } from '@/utils/lucide';

interface IconKey
{
	[key: string]: any;
}

const iconKey: IconKey = {
	//Document types
	journal_article: GraduationCap,
	press_release: Newspaper,
	sec_filing: Landmark,
	earnings_call_transcript: Archive,
	patent: Lightbulb,
	collaboration: UsersRound,
	company_presentation: Users,
	presentation: Users,
	scientific_poster: Image,
	trial: Hospital,

	//Drug Detail Top Row Icons
	product_status: Monitor,
	product_phase: Signal,
	product_type: Activity,
	product_therapeutic_class: PieChart,
	therapeutic_class: PieChart,
	deal: Quote,
	landscape: Map,
	unpublished: EyeOff,
	published: CheckCircle,
	clinical_pipeline: ClipboardList,
	clinical_pipeline_analysis: ClipboardList,
	disease_overview: FlaskConical,
	epidemiology: Users,
	fda_label_analysis: BookmarkPlus,
	report_builder: BookOpen,
	webpage: Globe,

	//Session type
	search: Search,
	comps: Target,
	list: Rows3,
	watchlist: List,
	question: Map,
	keyword: Tag,
	drug: Pill,
	market_map: BarChart,
	radar_chart: Target,
	target: Target,
	target_list: Target,

	workflow: FileSpreadsheet,
	report: Map,

	//Search focus filters
	company: Building2,
	date: Calendar,
	doc_type: FileText,
	tab: Globe,

	//Trial Event
	start: Flag,
	first_posted: FilePlus,
	//complete: Trophy,
	complete: Check,
	update: Edit,

	mail: Mail
};

export default function getIcon(icon_key: string)
{
	if (!iconKey[icon_key]) return iconKey['journal_article'];
	return iconKey[icon_key];
}

function hasIcon(icon_key: string)
{
	if (iconKey[icon_key]) return true;
	return false;
}

export { hasIcon };
