import { useFeatureFlagEnabled } from 'posthog-js/react';

import formatName from '@/utils/format/formatName';
import getIcon from '@/utils/getIcon';
import { cn } from '@/utils/lib/utils';
import { z_classes } from '@/utils/z';

import AdaptiveEnumDropdown from './Enum/AdaptiveEnumDropdown';
import Badge from './Layout/Badge';

const allowedTypeMapping = {
	profileViewer: ['drug', 'trial', 'company', 'indication', 'target', 'mechanism'],
	recordSearch: ['drug', 'trial', 'company', 'indication', 'target', 'mechanism', 'document', 'product_label'],
	opportunityPrioritizer: ['drug', 'trial', 'company', 'indication', 'target', 'mechanism'],
	clinicalDataExplorer: ['drug', 'trial', 'company'],
	watchlist: ['drug', 'trial', 'company'],
	marketScreen: ['drug', 'trial', 'company'],
	smartTable: ['drug', 'trial', 'company', 'document', 'target', 'mechanism', 'indication']
};

interface EntityTypeSelectorProps
{
	entityType: string;
	setEntityType: (entityType: string) => void;
	style?: 'grid' | 'badges' | 'tabs' | 'dropdown';
	set: keyof typeof allowedTypeMapping;
}

const EntityTypeSelector = ({ entityType, setEntityType, set, style = 'badges' }: EntityTypeSelectorProps) =>
{
	let typeArray = allowedTypeMapping[set];

	//If not internal, filter out product labels, as they're not available to external users yet.
	const isInternal = useFeatureFlagEnabled('internal');
	if (!isInternal)
	{
		typeArray = typeArray.filter(type => !['product_label'].includes(type));
	}

	switch (style)
	{
		case 'grid':
			return (
				<>
					{typeArray.map((type, idx) =>
					{
						const selected = entityType === type;

						let Icon = getIcon(type);

						return (
							<button
								key={type + idx}
								className={cn(
									'p-4 flex flex-col items-center justify-center gap-2 h-full w-full border rounded-md border-neutral-200 hover:border-maven-primary-500 transition-colors text-neutral-500 hover:text-neutral-900'
								)}
								onClick={() =>
								{
									setEntityType(type);
								}}
							>
								<Icon className='text-icon-xl' />
								<p className='text-base lg:text-xl font-medium'>{formatName(type)}</p>
							</button>
						);
					})}
				</>
			);
		case 'badges':
			return (
				<>
					{typeArray.map((type, idx) =>
					{
						const selected = entityType === type;
						let Icon = getIcon(type);

						return (
							<div key={idx} className='cursor-pointer m-1' onClick={() => setEntityType(type)}>
								<Badge
									value={formatName(type)}
									variant='primary'
									className={cn(
										'transition-all px-4 py-1 text-lg rounded-md',
										selected
											? 'bg-maven-primary-600 text-white hover:bg-maven-primary-600 hover:text-white border-2 border-maven-primary-600'
											: 'bg-white text-maven-primary-600 hover:bg-maven-primary-600 hover:text-white border-2 border-maven-primary-600'
									)}
								/>
							</div>
						);
					})}
				</>
			);
		case 'dropdown':
			//console.log('entityType', entityType, typeArray);

			return (
				<>
					<AdaptiveEnumDropdown
						initialSelectionIndices={[typeArray.indexOf(entityType)]}
						setValue={setEntityType}
						enumValues={typeArray}
						options={{
							selectionStyle: 'single',
							valueName: 'Entity Type'
						}}
						contentProps={{
							className: z_classes.r3f[3]
						}}
					/>
				</>
			);
	}
};

export default EntityTypeSelector;
export { allowedTypeMapping };
