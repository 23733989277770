import { setSessionData } from '@/redux/sessionSlice';
import store from '@/redux/store';

import Tab from '@/types/Tab';

const insertChat = (chat: Tab) =>
{
	const state = store.getState();
	const sessionData = state.session.sessionData;

	if (!sessionData)
	{
		console.error('No session data to update');
		return;
	}

	//This could be an existing chat:
	if (sessionData?.chats.find(existingChat => existingChat.id === chat.id))
	{
		const newSessionData = { ...sessionData };

		newSessionData.chats = newSessionData.chats.map(existingChat =>
		{
			if (existingChat.id === chat.id)
			{
				return chat;
			}
			return existingChat;
		});

		console.log('Updating with new chat data', newSessionData);
		store.dispatch(setSessionData(newSessionData));
	}
	//Otherwise, it's a new chat:
	else
	{
		console.log('New chat data');
		const newSessionData = { ...sessionData };

		newSessionData.chats = [...newSessionData.chats, chat];

		store.dispatch(setSessionData(newSessionData));
	}
};

export default insertChat;
