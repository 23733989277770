import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LayoutState
{
	actionBarAvailable: boolean;
	sidebarCollapsed: boolean;
	sidebarAvailable: boolean;
	rightSidebarCollapsed: boolean;
	rightSidebarAvailable: boolean;
	mainPanelCollapsed: boolean;

	mainPanelSize: number;
	rightPanelSize: number;

	overlay: any;
}

const initialState: LayoutState = {
	sidebarCollapsed: true,
	sidebarAvailable: true,
	rightSidebarCollapsed: true,
	rightSidebarAvailable: false,
	actionBarAvailable: true,
	mainPanelCollapsed: false,

	mainPanelSize: 0,
	rightPanelSize: 0,

	overlay: null
};

export const layoutSlice = createSlice({
	name: 'session',
	initialState,
	reducers: {
		setSidebarCollapsed: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, sidebarCollapsed: action.payload };
		},
		setSidebarAvailable: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, sidebarAvailable: action.payload };
		},
		setRightSidebarCollapsed: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, rightSidebarCollapsed: action.payload };
		},
		setRightSidebarAvailable: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, rightSidebarAvailable: action.payload };
		},
		setActionBarAvailable: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, actionBarAvailable: action.payload };
		},
		setMainPanelCollapsed: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, mainPanelCollapsed: action.payload };
		},
		setMainPanelSize: (state, action: PayloadAction<number>) =>
		{
			return { ...state, mainPanelSize: action.payload };
		},
		setRightPanelSize: (state, action: PayloadAction<number>) =>
		{
			return { ...state, rightPanelSize: action.payload };
		},
		setOverlay: (state, action: PayloadAction<any>) =>
		{
			return { ...state, overlay: action.payload };
		}
	}
});

export const {
	setSidebarCollapsed,
	setSidebarAvailable,
	setRightSidebarAvailable,
	setRightSidebarCollapsed,
	setActionBarAvailable,
	setMainPanelCollapsed,
	setMainPanelSize,
	setRightPanelSize,
	setOverlay
} = layoutSlice.actions;

export default layoutSlice.reducer;
