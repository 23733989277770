function highlightMatches(entity: any, filter: string)
{
	filter = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

	const highlightedEntity = { ...entity };

	if (highlightedEntity.title)
	{
		highlightedEntity.title = highlightedEntity.title.replace(new RegExp(filter, 'gi'), (match: any) =>
		{
			return `@@>${match}@@`;
		});
	}
	if (highlightedEntity.content)
	{
		highlightedEntity.content = highlightedEntity.content.replace(new RegExp(filter, 'gi'), (match: any) =>
		{
			return `@@>${match}@@`;
		});
	}
	if (highlightedEntity.text)
	{
		highlightedEntity.text = highlightedEntity.text.replace(new RegExp(filter, 'gi'), (match: any) =>
		{
			return `@@>${match}@@`;
		});
	}
	if (highlightedEntity.description)
	{
		highlightedEntity.description = highlightedEntity.description.replace(new RegExp(filter, 'gi'), (match: any) =>
		{
			return `@@>${match}@@`;
		});
	}
	if (highlightedEntity.context)
	{
		highlightedEntity.context = highlightedEntity.context.replace(new RegExp(filter, 'gi'), (match: any) =>
		{
			return `@@>${match}@@`;
		});
	}
	if (highlightedEntity.summary)
	{
		highlightedEntity.summary = highlightedEntity.summary.replace(new RegExp(filter, 'gi'), (match: any) =>
		{
			return `@@>${match}@@`;
		});
	}

	//Chunk text will be an array
	if (highlightedEntity.chunk_text)
	{
		highlightedEntity.chunk_text = highlightedEntity.chunk_text.map((chunk: any) =>
		{
			return chunk.replace(new RegExp(filter, 'gi'), (match: any) =>
			{
				return `@@>${match}@@`;
			});
		});
	}

	//Keys will be an array
	if (highlightedEntity.keys)
	{
		highlightedEntity.keys = highlightedEntity.keys.map((key: any) =>
		{
			return key.replace(new RegExp(filter, 'gi'), (match: any) =>
			{
				return `@@>${match}@@`;
			});
		});
	}

	return highlightedEntity;
}

function quickHighlightMatches(entity: string, filter: string)
{
	filter = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	return entity.replace(new RegExp(filter, 'gi'), (match: any) =>
	{
		return `@@>${match}@@`;
	});
}

export default highlightMatches;
export { quickHighlightMatches };
