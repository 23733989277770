import { createSession, fetchSessionsWithRetries } from '@/utils/mutateSession/fetch';
import { setCharts, setItems, setLists, setReports, setSessions } from '@/utils/mutateSession/fetch';

import { setOverlay, setSidebarCollapsed } from '@/redux/layoutSlice';
import { setSessionData } from '@/redux/sessionSlice';
import { setActiveSessionIndex } from '@/redux/sessionSlice';
import store from '@/redux/store';
import { setActiveTabIndex, setRenderedTabIndex, setRetrieving } from '@/redux/tabSlice';

import { SessionItemType } from '@/types/Session/SessionItem';

import anticipateDetail from '../anticipateRender/detail';
import formatName from '../format/formatName';
import getCurrentIds from '../getCurrentIds';
import insertTab from './insertTab';

function getSessionElementActions(type: SessionItemType, index: number, data: any)
{
	const sessions = store.getState().session.sessions;
	const accessToken = store.getState().user.token;
	const activeSessionIndex = store.getState().session.activeSessionIndex;

	let onClick;
	switch (type)
	{
		case 'session':
			onClick = () =>
			{
				if (index === activeSessionIndex)
				{
					return;
				}

				//Sesson retrieval will cause the active and rendered tabs to reset - handle that first
				store.dispatch(setRenderedTabIndex(-1));
				store.dispatch(setActiveTabIndex(-1));
				store.dispatch(setActiveSessionIndex(index));
			};
			break;

		case 'workflow':
			onClick = () =>
			{
				console.log('Clicked workflow', data);

				socket.emit('workflows', {
					action: 'get_workflow',
					payload: {
						flow_id: data.id
					}
				});

				const { session_id } = getCurrentIds();

				if (!session_id) return;

				const workflowType = data.workflow_type;

				if (!workflowType)
				{
					console.error('Invalid workflow type', workflowType);
					return;
				}

				if (workflowType === 'auto')
				{
					console.error('Invalid workflow passed through as auto', workflowType);
					return;
				}

				insertTab({
					id: workflowType,
					title: formatName(workflowType),
					actions: [
						{
							id: workflowType,
							action_id: workflowType,
							action_type: workflowType,
							tab_id: workflowType,
							data: {
								stepIndex: 0,
								researchColumnProps: {
									name: '',
									description: '',
									instructions: ''
								},
								customEnumValues: [],
								mode: workflowType === 'market_screen' ? 'automatic' : 'manual'
							}
						}
					],
					session_id,
					created_at: new Date().toISOString()
				});
			};
			break;

		//Detail after new session
		case 'report':
		case 'list':
		case 'chart':
		default:
			onClick = () =>
			{
				const { session_id } = getCurrentIds();

				let detailObj: any = {
					action_type: `${type}_detail`,
					id: data.id,
					session_id,
					tab_id: null,
					...data,
					data
				};

				detailObj[`${type}_id`] = data.id;

				anticipateDetail();

				store.dispatch(setSidebarCollapsed(true));
				store.dispatch(setOverlay(null));
				socket.emit('take_action', detailObj);

				return;
				//Set special logic for retrieving a detail.
				store.dispatch(setRetrieving(type));

				store.dispatch(setActiveSessionIndex(-1));
				createSession(accessToken).then(sessionData =>
				{
					fetchSessionsWithRetries(accessToken).then(newSessions =>
					{
						if (!newSessions)
						{
							console.log('Failed to retrieve list because latent session retrieval failed.');
							return;
						}

						const index = sessions.findIndex(session => session.session_id === sessionData.id);

						console.log('Creating new session for list', data.id, sessionData, 'with', newSessions, 'and so new index is', index);
						store.dispatch(setRenderedTabIndex(-1));
						store.dispatch(setActiveTabIndex(-1));
						store.dispatch(setActiveSessionIndex(index));

						let detailObj: any = {
							action_type: `${type}_detail`,
							id: data.id,
							session_id: sessionData.session_id,
							tab_id: null,
							...data,
							data
						};

						detailObj[`${type}_id`] = data.id;

						//Close the sidebar, anticipate the detail, and emit the action
						store.dispatch(setSidebarCollapsed(true));
						socket.emit('take_action', detailObj);

						//Anticipate the detail -- this is handled in the session element now
						/*setTimeout(async () =>
						{
							while (store.getState().session.sessionData === null)
							{
								console.log('Waiting for session data to load...');
								await new Promise(r => setTimeout(r, 50));
							}

							if (type === 'list') 
								anticipateListDetail(detailObj);
							else 
								anticipateDetail();
						}, 100);*/
					});
				});
			};
			break;
	}

	const updateSessions = (updatedData: any[]) =>
	{
		switch (type)
		{
			case 'session':
				setSessions(updatedData);
				break;
			case 'report':
				setReports(updatedData);
				break;
			case 'chart':
				setCharts(updatedData);
				break;
			case 'list':
				setLists(updatedData);
				break;
			default:
				console.log('Invalid type', type);
				break;
		}
	};

	//When deleting, we want to splice the data
	const splice = () =>
	{
		const updatedData = store.getState().session[`${type}s`].filter((item: any) => item.id !== data.id);
		updateSessions(updatedData);

		//Additionall, if this is in the items array, we want to remove it from the items array
		const items = store.getState().session.items.filter((item: any) => item.id !== data.id);
		setItems(items);
	};

	//After deletion, we want to refetch updated data
	const refetch = () =>
	{
		const fetchLength = store.getState().session[`${type}s`].length;

		console.log(`Refetching ${type}s - 0 -> ${fetchLength}`);
		socket.emit('get_activity_data', {
			entity_type: type + 's',
			page: 1,
			per_page: fetchLength
		});

		const itemFetchLength = store.getState().session.items.length;

		socket.emit('get_activity_data', {
			entity_type: 'items',
			page: 1,
			per_page: itemFetchLength
		});
	};

	let onDeleteClick;
	switch (type)
	{
		case 'session':
			onDeleteClick = (e: any) =>
			{
				const active = !!sessions && sessions.length > 1 && sessions.indexOf(data) === activeSessionIndex;
				const shiftIndex = !!sessions && sessions.length > 1 && sessions.indexOf(data) <= activeSessionIndex;

				console.log(
					'Deleting session',
					data.id,
					activeSessionIndex,
					'deleting index',
					sessions.indexOf(data),
					'active:',
					active,
					'shiftIndex:',
					shiftIndex
				);

				if (sessions?.length === 1) store.dispatch(setActiveSessionIndex(-1));

				console.log('Deleting session', data.id, 'active:', active, 'shiftIndex:', shiftIndex);
				e.stopPropagation();
				fetch(process.env.NEXT_PUBLIC_BACKEND_URL + '/sessions/' + data.id, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}).then(() =>
				{
					splice();
					refetch();
					if (active)
					{
						store.dispatch(setActiveTabIndex(-1));
						store.dispatch(setRenderedTabIndex(-1));
						setSessionData(null);
						store.dispatch(setActiveSessionIndex(Math.max(0, activeSessionIndex - 1)));
					}
					else if (shiftIndex)
					{
						//sessionDeleteDebounce.current = true;
						store.dispatch(setActiveSessionIndex(Math.max(0, activeSessionIndex - 1)));
					}
				});
			};
			break;

		case 'list':
			onDeleteClick = (e: any) =>
			{
				console.log(`Deleting list`, data.id);

				e.stopPropagation();
				e.preventDefault();

				socket.emit('list_action', {
					action_type: 'delete_list',
					id: data.id
				});

				splice();
				refetch();
			};
			break;

		case 'chart':
			onDeleteClick = (e: any) =>
			{
				console.log('Deleting chart', data.id);
				e.stopPropagation();
				e.preventDefault();
				socket.emit('delete_chart', {
					chart_id: data.id
				});
				return;
			};
			break;

		case 'workflow':
			onDeleteClick = (e: any) =>
			{
				console.log('Deleting workflow', data.id);
				e.stopPropagation();
				e.preventDefault();
				socket.emit('workflows', {
					action: 'delete_workflow',
					payload: {
						flow_id: data.id
					}
				});

				splice();
				refetch();
				return;
			};
			break;

		case 'report':
			onDeleteClick = (e: any) =>
			{
				console.log('Deleting report', data.id);
				e.stopPropagation();
				e.preventDefault();
				socket.emit('report_action', {
					action_type: 'delete_report',
					id: data.id
				});

				splice();
				refetch();
				return;
			};

			break;
		default:
			onDeleteClick = (e: any) =>
			{
				console.log('Deleting', type, data.id);
				e.stopPropagation();
				fetch(process.env.NEXT_PUBLIC_BACKEND_URL + `/${type}s/` + data.id, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}).then(() =>
				{
					splice();
					refetch();
				});
			};
			break;
	}

	return { onClick, onDeleteClick };
}

export default getSessionElementActions;
