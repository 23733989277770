import { useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import { markdownToComponent } from '@/utils/convertMarkdown';
import { cn } from '@/utils/lib/utils';
import { Check } from '@/utils/lucide';
import { Copy } from '@/utils/lucide';

interface CopyTextProps
{
	text: string;
	className?: string;
	richCopy?: boolean;
	spanProps?: React.HTMLProps<HTMLParagraphElement>;
	displayText?: string;
}

const CopyText = ({ text, className, spanProps, richCopy, displayText }: CopyTextProps) =>
{
	const [copied, setCopied] = useState(false);

	function copyToClipboard()
	{
		if (richCopy)
		{
			//Convert markdown first
			const component = markdownToComponent(text);
			const htmlText = ReactDOMServer.renderToStaticMarkup(component);

			navigator.clipboard.write([
				new ClipboardItem({
					'text/html': new Blob([htmlText], { type: 'text/html' }),
					'text/plain': new Blob([text], { type: 'text/plain' })
				})
			]);
		}
		else
		{
			navigator.clipboard.writeText(text);
		}
	}

	return (
		<p
			onClick={e =>
			{
				copyToClipboard();
				setCopied(true);
				setTimeout(() => setCopied(false), 2000);
				e.stopPropagation();
			}}
			className={cn(
				'flex flex-row items-center text-icon text-neutral-400 min-w-4 min-h-4 opacity-0 group-hover:opacity-100 transition-all cursor-pointer hover:text-neutral-700',
				className
			)}
			{...spanProps}
		>
			{copied ? <Check /> : <Copy />}
			{displayText && <span className='hidden @md:flex ml-2 text-base'>{displayText}</span>}
		</p>
	);
};

export default CopyText;
