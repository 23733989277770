import { resetFocusFilters } from '@/components/Layout/Focus';

import { logPerformance, setSessionsFetchTime } from '@/utils/performance';

import {
	setActiveSessionIndex,
	setActiveSessionIndex as setActiveSessionIndexRedux,
	setCharts as setChartsRedux,
	setItems as setItemsRedux,
	setLists as setListsRedux,
	setMonitors as setMonitorsRedux,
	setPlanInfo as setPlanInfoRedux,
	setPlan as setPlanRedux,
	setReports as setReportsRedux,
	setSessionData,
	setSessions as setSessionsRedux,
	setSessionsSucceeded as setSessionsSucceededRedux,
	setSupportState as setSupportStateRedux
} from '@/redux/sessionSlice';
import store, { AppDispatch, RootState } from '@/redux/store';
import { setActiveActionIndex, setActiveChatIndex, setActiveTabIndex, setBlocking, setRenderedTabIndex } from '@/redux/tabSlice';

import Session from '@/types/Session';

import pluralize from '../format/pluralize';
import { itemsLoaded } from '../monitors/itemsLoaded';

const setSessions = (sessions: Session[]) =>
{
	store.dispatch(setSessionsRedux(sessions));
};

const setItems = (items: any) =>
{
	store.dispatch(setItemsRedux(items));
};

const setReports = (reports: any) =>
{
	store.dispatch(setReportsRedux(reports));
};

const setCharts = (charts: any) =>
{
	store.dispatch(setChartsRedux(charts));
};

const setLists = (lists: any) =>
{
	store.dispatch(setListsRedux(lists));
};

const setMonitors = (monitors: any) =>
{
	store.dispatch(setMonitorsRedux(monitors));
};

const setPlan = (plan: string | null) =>
{
	store.dispatch(setPlanRedux(plan));
};

const setPlanInfo = (planInfo: any) =>
{
	store.dispatch(setPlanInfoRedux(planInfo));
};

//Functions for fetching sessions, data, and state, as well as socket management
const fetchSessions = async (accessToken: string): Promise<any[] | false> =>
{
	console.log('Fetching sessions');

	const result = await fetch(process.env.NEXT_PUBLIC_BACKEND_URL + '/sessions', {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then(async response =>
		{
			if (response.ok)
			{
				const data = await response.json();

				if (logPerformance)
				{
					setSessionsFetchTime(performance.now());
				}

				const sessions = data.sessions.sort((a: Session, b: Session) =>
				{
					return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
				});

				console.log('Retrieved sessions', data, sessions);

				setSessions(sessions);
				setItems(data.items);
				setPlan(data.plan);
				setPlanInfo(data.plan_info);

				//If there's a support key, we should fill in the state correspondingly
				if (data.support)
				{
					store.dispatch(
						setSupportStateRedux({
							...data.support,
							isNew: data.is_new
						})
					);
				}

				if (data.monitors)
				{
					//Wait until loaded for all given monitors
					while (data.monitors?.every((monitor: any) => !itemsLoaded(monitor.entity_links)))
					{
						//console.log('Waiting for items to load for', data.data);
						await new Promise(r => setTimeout(r, 100));
					}

					//Need to convert entity_links to entities
					let enums = store.getState().enums.enums;
					const monitors = data.monitors.map((monitor: any) =>
					{
						const entities = monitor.entity_links.map((entity_link: any) =>
						{
							const typeEnum = enums[pluralize(entity_link.entity_type)] as any[];
							const entity = typeEnum.find((enumItem: any) => enumItem.id === entity_link.entity_id);

							return {
								...entity_link,
								...entity
							};
						});

						return {
							...monitor,
							entities
						};
					});

					setMonitors(monitors);
				}

				return sessions;
			}
			else
			{
				console.error('Failed to fetch sessions');
				return false;
			}
		})
		.catch(error =>
		{
			console.error('Failed to fetch sessions', error);
			return false;
		});

	return result;
};

const fetchSessionsWithRetries = async (accessToken: string) =>
{
	let success = false;
	let retries = 0;
	let retryDelay = 1000;
	while (!success)
	{
		const sessions = await fetchSessions(accessToken);

		if (sessions !== false)
		{
			store.dispatch(setSessionsSucceededRedux(true));
			success = true;
			return sessions;
		}

		//Wait connection delay, increment retries, double retry delay, and try again.
		console.log('Retry ', +retries, ' in ', retryDelay, 'ms');
		await new Promise(r => setTimeout(r, retryDelay));
		if (retryDelay < 10000) retryDelay *= 1.5;
		retries++;

		if (retries >= 3)
		{
			setSessions([]);
			store.dispatch(setSessionsSucceededRedux(false));
			return false;
		}
	}

	return false;
};

const createSession = async (accessToken: string) =>
{
	const response = await fetch(process.env.NEXT_PUBLIC_BACKEND_URL + '/sessions', {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	});

	store.dispatch(setBlocking({ blocking: false, reason: null }));

	if (response.ok)
	{
		const data = await response.json();
		console.log('Created new session', data);
		return data;
	}
	else
	{
		console.error('Failed to create session');
	}
};

const newSessionAction = async (accessToken: string) =>
{
	try
	{
		store.dispatch(setSessionData(null));
		const sessionData = await createSession(accessToken);
		const sessionsResult = await fetchSessionsWithRetries(accessToken);

		if (sessionsResult === false)
		{
			console.error('Failed to fetch sessions after creating new session');
			return {
				success: false,
				error: 'Failed to fetch sessions after creating new session'
			};
		}

		const sessions = sessionsResult as Session[];

		let index = sessions.findIndex(session => session.id === sessionData.session_id);
		if (index === -1)
		{
			console.log('Failed to find session index for new session', sessionData, sessions);
			index = 0;
		}

		store.dispatch(setActiveSessionIndex(index));
		socket.emit('open_session', { session_id: sessionData.session_id });
		store.dispatch(setRenderedTabIndex(-1));
		store.dispatch(setActiveTabIndex(0));
		store.dispatch(setBlocking({ blocking: false, reason: null }));
		resetFocusFilters();

		console.log('New session action', sessionData, index);
		return {
			success: true,
			sessionData,
			id: sessionData.id,
			index
		};
	}
	catch (error)
	{
		console.error('Failed to create new session', error);
		return {
			success: false,
			error
		};
	}
};

export {
	fetchSessions,
	fetchSessionsWithRetries,
	createSession,
	newSessionAction,
	setSessions,
	setReports,
	setCharts,
	setLists,
	setPlan,
	setItems,
	setMonitors
};
