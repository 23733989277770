//Sort by relevance to a string filter
function filterRelevance(entity: any, filter: string)
{
	try
	{
		filter = filter.toLowerCase();
		const title = entity.title?.toLowerCase() ?? '';
		const textContent = (entity.content || entity.text || entity.description || entity.context || '').toLowerCase();

		//Exact string matching
		if (title === filter) return 10;
		if (textContent === filter) return 9;

		//Partial string matching, including word match
		if (title.split(' ').includes(filter)) return 8;
		if (title.includes(filter)) return 7;
		if (textContent.includes(filter)) return 6;
		if (textContent.split(' ').includes(filter)) return 5;

		//Match any key in the entity
		for (let key in entity)
		{
			if (entity[key] === filter) return 4;
			if (entity[key] && typeof entity[key] === 'string' && entity[key].includes(filter)) return 3;
			if (JSON.stringify(entity[key]).includes(filter)) return 2;
		}
		if (entity.keys)
		{
			for (let key in entity.keys)
			{
				if (entity[key] === filter) return 4;
				if (entity[key] && typeof entity[key] === 'string' && entity[key].includes(filter)) return 3;
			}
		}

		//Any matching at all
		if (JSON.stringify(entity).toLowerCase().includes(filter.toLowerCase())) return 1;

		//No match
		return 0;
	}
	catch (e)
	{
		console.error(e);
		return 0;
	}
}

function quickFilterRelevance(entity: string, filter: string)
{
	try
	{
		filter = filter.toLowerCase();
		entity = entity.toLowerCase();

		//Exact string matching
		if (entity === filter) return 10;

		//Partial string matching, including word match
		if (entity.split(' ').includes(filter)) return 8;
		if (entity.includes(filter)) return 7;

		//No match
		return 0;
	}
	catch (e)
	{
		console.error(e);
		return 0;
	}
}

function quickestFilterRelevance(entity: string, filter: string)
{
	if (!entity || !filter) return 0;

	try
	{
		filter = filter.toLowerCase();
		entity = entity.toLowerCase();

		//Exact string matching
		if (entity === filter) return 10;

		//Partial string matching, including word match
		if (entity.includes(filter)) return 7;

		//No match
		return 0;
	}
	catch (e)
	{
		console.error(e);
		return 0;
	}
}

function strippedFilterRelevance(entity: string, filter: string)
{
	try
	{
		filter = filter.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
		entity = entity.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

		//Exact string matching
		if (entity === filter) return 10;

		//Partial string matching, including word match
		if (entity.includes(filter)) return 7;

		//No match
		return 0;
	}
	catch (e)
	{
		console.error(e);
		return 0;
	}
}

export default filterRelevance;
export { quickFilterRelevance, quickestFilterRelevance, strippedFilterRelevance };
