import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import Session from '@/types/Session';

interface NotificationsState
{
	notifications: any[];
	loading?: boolean;
	totalCount?: number;
}

const initialState: NotificationsState = {
	notifications: [],
	loading: false,
	totalCount: 0
};

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		setNotifications: (state, action: PayloadAction<any[]>) =>
		{
			return { ...state, notifications: action.payload };
		},
		setNotificationsLoading: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, loading: action.payload };
		},
		setNotificationsTotalCount: (state, action: PayloadAction<number>) =>
		{
			return { ...state, totalCount: action.payload };
		}
	}
});

export const { setNotifications, setNotificationsLoading, setNotificationsTotalCount } = notificationsSlice.actions;
export default notificationsSlice.reducer;
