'use client';

import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuRadioGroup,
	DropdownMenuRadioItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';

import formatName from '@/utils/format/formatName';
import { cn } from '@/utils/lib/utils';

type EnumDropdownProps = {
	enumValues: Array<string>;
	value: string;
	onChange: any;
	contentClassName?: string;
};

const EnumDropdown = ({ enumValues, value, onChange, contentClassName }: EnumDropdownProps) =>
{
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button className='mx-2' variant='outline'>
					{formatName(value)}
					<p className='material-icons'>expand_more</p>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className={cn(`w-56`, contentClassName)}>
				<DropdownMenuRadioGroup value={value} onValueChange={onChange} className='max-h-[30vh] overflow-y-auto'>
					{Object.values(enumValues).map(item => (
						<DropdownMenuRadioItem key={item} value={item}>
							{formatName(item)}
						</DropdownMenuRadioItem>
					))}
				</DropdownMenuRadioGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default EnumDropdown;
