import { format } from 'date-fns';

import { setDeepResearch } from '@/redux/focusSlice';
import { setMainPanelCollapsed, setRightSidebarCollapsed } from '@/redux/layoutSlice';
import store from '@/redux/store';
import { setBlocking } from '@/redux/tabSlice';

import Session from '@/types/Session';

import anticipateMessageAndResponse from '../anticipateRender/messageAndResponse';
import getCurrentIds from '../getCurrentIds';
import getVisibleElement from '../getVisibleElement';

const sendChatMessage = (message: string) =>
{
	message = message.trim();

	if (message.length === 0)
	{
		return;
	}

	//If the active input is the start card action input with id action-input-startcard, then de-select it:
	const activeElement = document.activeElement;
	if (activeElement && activeElement.id === 'action-input-startcard')
	{
		(activeElement as HTMLElement).blur();
	}

	const state = store.getState();
	const session: Session | null = state.session.sessionData;
	const activeTabIndex = state.tab.activeTabIndex;
	let activeChatIndex = state.tab.activeChatIndex;
	if (activeChatIndex === -1) activeChatIndex = 0;

	//console.log("Sending message", message, ' on ', activeTabIndex, ':', session?.tabs[activeTabIndex].id);
	console.log('Sending message:', message, ' on chat:', activeChatIndex, 'chat_id: ', session?.chats[activeChatIndex].id);

	//Extract entity restrictions, limit to 25
	const company_restriction_names = state.focus.company?.filter(entity => entity !== 'Any Company').slice(0, 25) ?? [];
	const company_restrictions =
		state.enums.enums.companies?.filter((company: { id: string; name: string }) => company_restriction_names.includes(company.name)) ?? [];
	const drug_restriction_names = state.focus.drug?.filter(entity => entity !== 'Any Drug').slice(0, 25) ?? [];
	const drug_restrictions = state.enums.enums.drugs?.filter((drug: { id: string; name: string }) => drug_restriction_names.includes(drug.name)) ?? [];

	//Limit to 25 entities
	const entity_restrictions = [...company_restrictions, ...drug_restrictions].slice(0, 25);

	//Pull deep research flag
	const deepResearch = state.focus.deepResearch;

	socket.emit('take_action', {
		action_type: 'message',
		session_id: session?.id,
		active_tab_id: session?.tabs[activeTabIndex].id,
		chat_id: session?.chats[activeChatIndex].id,
		message,
		deep_research: deepResearch,
		//If there's focus filters applied, send them along with the message
		focus:
			state.focus.focusSelection === 'focus'
				? {
						type: 'filter',
						filters: {
							start_date: format(state.focus.startDate, 'MM-dd-yyyy'),
							end_date: format(state.focus.endDate, 'MM-dd-yyyy'),
							document_type: state.focus.documentType,
							entity_restrictions: entity_restrictions,
							reference_tabs: state.focus.tab.id ? [state.focus.tab.id] : []
						}
					}
				: {
						type: 'items',
						items: state.focus.items.map(entity => ({
							...entity,
							entity_id: entity.id
						}))
					}
	});

	//If deep research is true, then set the deep research flag to false
	if (deepResearch)
	{
		store.dispatch(setDeepResearch(false));
	}

	if (session)
	{
		//Update the tab state based on the message.

		//Right panel should definitely be visible if there are actions in the tab.
		if (session.tabs[activeTabIndex].actions.length === 0)
		{
			store.dispatch(setRightSidebarCollapsed(false));
		}

		//If all the tabs have no actions, then the right side panel should fully expand.
		if (session.tabs.every(tab => tab.actions.length === 0))
		{
			store.dispatch(setMainPanelCollapsed(true));
		}

		anticipateMessageAndResponse(message);
	}

	//While a message is generating, block future actions
	store.dispatch(setBlocking({ blocking: true, reason: 'generating' }));
};

export default sendChatMessage;
