'use client';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import anticipateDetail from '@/utils/anticipateRender/detail';
import { markdownToComponent } from '@/utils/convertMarkdown';
import { strippedFilterRelevance } from '@/utils/filtering/filterRelevance';
import { quickHighlightMatches } from '@/utils/filtering/highlightMatches';
import variantFilterRelevance from '@/utils/filtering/variantFilterRelevance';
import formatName from '@/utils/format/formatName';
import pluralize from '@/utils/format/pluralize';
import getCurrentIds from '@/utils/getCurrentIds';
import getIcon from '@/utils/getIcon';
import { cn } from '@/utils/lib/utils';
import { AlertTriangle, ChevronDown, ChevronUp, Search, SearchX, Send, X } from '@/utils/lucide';
import getSessionElementActions from '@/utils/mutateSession/getSessionElementActions';
import insertTab from '@/utils/mutateSession/insertTab';
import sendChatMessage from '@/utils/mutateSession/sendChatMessage';
import { z_classes } from '@/utils/z';

import { setOverlay } from '@/redux/layoutSlice';
import store, { RootState } from '@/redux/store';

import { SessionItemType } from '@/types/Session/SessionItem';

import { allowedTypeMapping } from './EntityTypeSelector';
import { PrecomputedValueSet } from './Enum/NestedSelectDropdownPC';
import NavIcon from './Layout/NavIcon';
import StartCard from './Tab/StartCards/StartCard';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from './ui/collapsible';
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip';

const filterLengthThreshold = 4;

interface SearchItem
{
	id: string;
	name: string;
	name_variants?: string[] | null | undefined;
	type: string;
	category: 'work' | 'content' | 'tool';
	ticker?: string;
	nct_id?: string;
}

interface FilteredResults
{
	work: SearchItem[];
	tools: SearchItem[];
	content: SearchItem[];
}

interface SectionState
{
	isExpanded: boolean;
}

interface UnifiedNavigationItemProps
{
	item: SearchItem;
	searchTerm: string;
}

const UnifiedNavigationItem: React.FC<UnifiedNavigationItemProps & { isFocused?: boolean }> = ({ item, searchTerm, isFocused }) =>
{
	let highlighted = quickHighlightMatches(item.name, searchTerm);
	const Icon = getIcon(item.type);

	//If the term does not match the name, we need to check the name variants
	if (strippedFilterRelevance(item.name, searchTerm) === 0)
	{
		let targetMatch =
			item.name_variants?.find((variant: string) => strippedFilterRelevance(variant, searchTerm) > 0) ??
			(item.ticker && strippedFilterRelevance(item.ticker, searchTerm) > 0 ? item.ticker : '') ??
			(item.nct_id && strippedFilterRelevance(item.nct_id, searchTerm) > 0 ? item.nct_id : '');

		if (targetMatch && targetMatch.length > 0)
		{
			targetMatch = targetMatch.replace(/\n/gi, ' ').replace(/\s+/gi, ' ').trim();
			highlighted = highlighted + ' (' + quickHighlightMatches(targetMatch, searchTerm) + ')';
		}
	}

	const handleClick = () =>
	{
		// Handle item click based on category
		if (item.category === 'work')
		{
			const handler = getSessionElementActions(item.type as SessionItemType, 0, item);
			handler.onClick();
			store.dispatch(setOverlay(null));
		}
		else if (item.category === 'content')
		{
			const { tab_id, session_id } = getCurrentIds();
			socket.emit('take_action', {
				action_type: `${item.type}_detail`,
				id: item.id,
				[`${item.type}_id`]: item.id,
				session_id
			});
			anticipateDetail();
			store.dispatch(setOverlay(null));
		}
	};

	return (
		<div
			key={item.id || item.name}
			className={cn(
				'flex items-center justify-between p-2 gap-4 rounded-md hover:bg-muted cursor-pointer group hover:bg-neutral-100 active:bg-neutral-200',
				isFocused && 'bg-neutral-100'
			)}
			onClick={handleClick}
		>
			<div className='flex-1'>{markdownToComponent(highlighted)}</div>
			<div className='flex items-center gap-2 px-3 py-2 text-sm bg-neutral-100 rounded'>
				{Icon && (
					<span className='text-icon-sm'>
						<Icon />
					</span>
				)}
				{formatName(item.type)}
			</div>
		</div>
	);
};

interface UnifiedNavigationSectionProps
{
	title: string;
	items?: SearchItem[];
	sectionKey: 'tools' | 'work' | 'content';
	sectionStates: Record<string, SectionState>;
	setSectionStates: React.Dispatch<React.SetStateAction<Record<string, SectionState>>>;
	searchTerm: string;
	focusedIndex: number;
	visibleItemStartIndex: number;
}

const UnifiedNavigationSection: React.FC<UnifiedNavigationSectionProps> = ({
	title,
	items,
	sectionKey,
	sectionStates,
	setSectionStates,
	searchTerm,
	focusedIndex,
	visibleItemStartIndex
}) =>
{
	const [open, setOpen] = useState(true);

	if (!items?.length) return null;

	const isExpanded = sectionStates[sectionKey].isExpanded;
	const displayItems = isExpanded ? items.slice(0, 25) : items.slice(0, 4);
	const hasMore = items.length > 4;

	const handleSectionToggle = () =>
	{
		setSectionStates(prev => ({
			...prev,
			[sectionKey]: { isExpanded: !prev[sectionKey].isExpanded }
		}));
	};

	const handleShowMoreClick = (e: React.MouseEvent) =>
	{
		e.stopPropagation();
		handleSectionToggle();
	};

	return (
		<Collapsible open={open} onOpenChange={setOpen}>
			<CollapsibleTrigger>
				<div className='flex items-center justify-between cursor-pointer gap-2'>
					<h2 className='text-lg font-semibold'>{title}</h2>
					{open ? <ChevronUp className='h-4 w-4 text-icon text-neutral-500' /> : <ChevronDown className='h-4 w-4 text-icon text-neutral-500' />}
				</div>
			</CollapsibleTrigger>
			<CollapsibleContent className='gap-2'>
				<div className='space-y-1'>
					{displayItems.map((item, idx) => (
						<UnifiedNavigationItem
							key={item.id || item.name}
							item={item}
							searchTerm={searchTerm}
							isFocused={focusedIndex === visibleItemStartIndex + idx}
						/>
					))}
					{hasMore && (
						<Button
							variant='ghost'
							className='w-full flex items-center justify-center gap-2 text-neutral-500 hover:text-neutral-700 active:text-neutral-900'
							onClick={handleShowMoreClick}
						>
							<div className='flex items-center gap-2'>
								{isExpanded ? <ChevronUp className='h-4 w-4' /> : <ChevronDown className='h-4 w-4' />}
								<span>{isExpanded ? 'Show Less' : `Show More (${items.length - 4} more)`}</span>
							</div>
						</Button>
					)}
				</div>
			</CollapsibleContent>
		</Collapsible>
	);
};

interface EmptyStateProps
{
	searchTerm: string;
	filteredItems: FilteredResults;
}

const EmptyState: React.FC<EmptyStateProps> = ({ searchTerm, filteredItems }) =>
{
	if (searchTerm.length >= filterLengthThreshold && !filteredItems.tools?.length && !filteredItems.work?.length && !filteredItems.content?.length)
	{
		return (
			<div className='flex flex-col items-center justify-center min-h-[240px] gap-2'>
				<p className='p-4 bg-neutral-100 rounded-full text-neutral-700'>
					<SearchX className='text-icon-lg' />
				</p>
				<p className='text-xl'>No results found</p>
				<p className='text-neutral-500'>Try adjusting your search terms</p>
			</div>
		);
	}

	if (searchTerm.length < filterLengthThreshold)
	{
		return (
			<div className='flex flex-col items-center justify-center min-h-[240px] gap-2'>
				<p className='p-4 bg-neutral-100 rounded-full text-neutral-700'>
					<Search className='text-icon-lg' />
				</p>
				<p className='text-xl'>Quick Lookup</p>
				<p className='text-neutral-500 text-center'>Type to find the work item or profile you need.</p>
			</div>
		);
	}

	return null;
};

interface SearchHintProps
{
	searchTerm: string;
}

const SearchHint: React.FC<SearchHintProps> = ({ searchTerm }) =>
{
	if (searchTerm.length > 12)
	{
		return (
			<div className='flex items-center justify-between p-4 mb-4 bg-neutral-50 rounded-lg border border-neutral-200'>
				<p className='text-neutral-600'>Did you mean to send this as a query to the Assistant?</p>
				<Button
					variant='ghost'
					className='flex items-center gap-2'
					onClick={() =>
					{
						store.dispatch(setOverlay(null));
						sendChatMessage(searchTerm);
					}}
				>
					<Send className='h-4 w-4' />
					Send to Assistant
				</Button>
			</div>
		);
	}
	return null;
};

const UnifiedNavigationModal = () =>
{
	const inputRef = useRef<HTMLInputElement>(null);
	const inputTimer = useRef<any>();
	const enumUpdateTimer = useRef<any>();
	const [focusedIndex, setFocusedIndex] = useState<number>(-1);

	const [inputValue, setInputValue] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [filterWarning, setFilterWarning] = useState<string | null>(null);
	const [bufferedEnumsState, setBufferedEnumsState] = useState<typeof enumsState>({});
	const [sectionStates, setSectionStates] = useState<Record<string, SectionState>>({
		tools: { isExpanded: false },
		work: { isExpanded: false },
		content: { isExpanded: false }
	});

	//On open, focus the input
	useEffect(() =>
	{
		inputRef.current?.focus();
	}, []);

	// Get enums state for content search
	const enumsState = useSelector((state: RootState) => state.enums.enums);

	// Buffer enum updates
	useEffect(() =>
	{
		if (enumUpdateTimer.current)
		{
			clearTimeout(enumUpdateTimer.current);
		}

		enumUpdateTimer.current = setTimeout(() =>
		{
			setBufferedEnumsState(enumsState);
		}, 300); // 300ms buffer, same as input debounce

		return () =>
		{
			if (enumUpdateTimer.current)
			{
				clearTimeout(enumUpdateTimer.current);
			}
		};
	}, [enumsState]);

	// Reset section states when search term changes
	useEffect(() =>
	{
		setSectionStates({
			tools: { isExpanded: false },
			work: { isExpanded: false },
			content: { isExpanded: false }
		});
	}, [searchTerm]);

	// Get session items (Your Work)
	const rawSessionItems = useSelector((state: RootState) => state.session.items);

	const sessionItems = useMemo(() =>
	{
		return rawSessionItems.map((item: any) => ({
			...item,
			id: item.id || `session-${item.title}`,
			category: 'work' as const,
			type: item.type || item.session_type || 'session',
			name: item.title
		})) as SearchItem[];
	}, [rawSessionItems]);

	const loadingEnum = useMemo(() =>
	{
		//If any of the keys from allowedTypeMapping['profileViewer'] are not in bufferedEnumsState, return the first key that is not in bufferedEnumsState
		const keys = allowedTypeMapping['profileViewer'].map(key => pluralize(key));
		if (keys.some(key => !bufferedEnumsState[key]))
		{
			return keys.find(key => !bufferedEnumsState[key]);
		}
		return false;
	}, [bufferedEnumsState]);

	// Filter and sort items
	const filteredItems = useMemo<FilteredResults>(() =>
	{
		console.log('Running filter and sort', searchTerm, sessionItems);

		if (!searchTerm || searchTerm.length < filterLengthThreshold)
		{
			if (searchTerm && searchTerm.length > 0)
			{
				setFilterWarning(`Search term must be at least ${filterLengthThreshold} characters - try refining your search.`);
			}
			else
			{
				setFilterWarning(null);
			}
			return { work: [], tools: [], content: [] };
		}

		setFilterWarning(null);

		// Filter work items
		const matchingWork = sessionItems
			.filter(item => variantFilterRelevance(item, searchTerm, true) > 0)
			.sort((a, b) => variantFilterRelevance(b, searchTerm, true) - variantFilterRelevance(a, searchTerm, true))
			.slice(0, 25);

		// Filter content items - search each enum category separately
		const matchingContentByType: SearchItem[] = [];
		const keys = allowedTypeMapping['profileViewer'] as string[];

		keys.forEach(key =>
		{
			const pluralKey = pluralize(key);
			const values = bufferedEnumsState[pluralKey];
			if (!values) return; // Skip if enum not loaded

			let matches: SearchItem[] = [];
			if (Array.isArray(values))
			{
				matches = values
					.filter(v =>
					{
						if (typeof v === 'string')
						{
							return strippedFilterRelevance(v, searchTerm) > 0;
						}
						return variantFilterRelevance(v, searchTerm, true) > 0;
					})
					.map(v => ({
						...v,
						type: key,
						category: 'content' as const
					}))
					.sort((a, b) =>
					{
						const aRelevance = variantFilterRelevance(a, searchTerm, true);
						const bRelevance = variantFilterRelevance(b, searchTerm, true);
						return bRelevance - aRelevance;
					})
					.slice(0, 5); // Take top 5 from each category
			}
			else if (values.sortedDistinctValuesWithVariants)
			{
				matches = (values.sortedDistinctValuesWithVariants as any[])
					.filter(v =>
					{
						if (typeof v === 'string')
						{
							return strippedFilterRelevance(v, searchTerm) > 0;
						}
						return variantFilterRelevance(v, searchTerm, true) > 0;
					})
					.map(v => ({
						...v,
						type: key,
						category: 'content' as const
					}))
					.sort((a, b) =>
					{
						const aRelevance = variantFilterRelevance(a, searchTerm, true);
						const bRelevance = variantFilterRelevance(b, searchTerm, true);
						return bRelevance - aRelevance;
					})
					.slice(0, 5);
			}

			matchingContentByType.push(...matches);
		});

		// Sort all content matches by relevance and take top 25
		const matchingContent = matchingContentByType
			.sort((a, b) =>
			{
				let aRelevance = variantFilterRelevance(a, searchTerm, true);
				let bRelevance = variantFilterRelevance(b, searchTerm, true);

				if (a.type === 'company') aRelevance += 1;
				if (a.type === 'drug') aRelevance += 0.5;
				if (a.type === 'trial') aRelevance -= 3;

				if (b.type === 'company') bRelevance += 1;
				if (b.type === 'drug') bRelevance += 0.5;
				if (b.type === 'trial') bRelevance -= 3;

				return bRelevance - aRelevance;
			})
			.slice(0, 25);

		return {
			work: matchingWork,
			tools: [], // Empty for now as requested
			content: matchingContent
		};
	}, [searchTerm, sessionItems, bufferedEnumsState]);

	// Get all visible items across sections for keyboard navigation
	const allVisibleItems = useMemo(() =>
	{
		if (!searchTerm || searchTerm.length < filterLengthThreshold) return [];

		const items: SearchItem[] = [];
		if (filteredItems.tools?.length)
		{
			items.push(...(sectionStates.tools.isExpanded ? filteredItems.tools.slice(0, 25) : filteredItems.tools.slice(0, 4)));
		}
		if (filteredItems.work?.length)
		{
			items.push(...(sectionStates.work.isExpanded ? filteredItems.work.slice(0, 25) : filteredItems.work.slice(0, 4)));
		}
		if (filteredItems.content?.length)
		{
			items.push(...(sectionStates.content.isExpanded ? filteredItems.content.slice(0, 25) : filteredItems.content.slice(0, 4)));
		}
		return items;
	}, [filteredItems, sectionStates, searchTerm]);

	// Reset focus when search term changes or when visible items change
	useEffect(() =>
	{
		setFocusedIndex(-1);
	}, [searchTerm, allVisibleItems.length]);

	const handleKeyDown = (e: React.KeyboardEvent) =>
	{
		if (!allVisibleItems.length) return;

		console.log('Focused index', focusedIndex);

		switch (e.key)
		{
			case 'ArrowDown':
				e.preventDefault();
				setFocusedIndex(prev =>
				{
					// If not focused or at end, go to first item
					if (prev === -1) return 0;
					// If at last item, stay there
					if (prev >= allVisibleItems.length - 1) return prev;
					// Otherwise, go to next item
					return prev + 1;
				});
				break;

			case 'ArrowUp':
				e.preventDefault();
				setFocusedIndex(prev =>
				{
					// If at first item or not focused, stay at first item
					if (prev <= 0) return 0;
					// Otherwise, go to previous item
					return prev - 1;
				});
				break;

			case 'Enter':
				if (focusedIndex >= 0 && focusedIndex < allVisibleItems.length)
				{
					e.preventDefault();
					const selectedItem = allVisibleItems[focusedIndex];
					if (selectedItem)
					{
						if (selectedItem.category === 'work')
						{
							const handler = getSessionElementActions(selectedItem.type as SessionItemType, 0, selectedItem);
							handler.onClick();
							store.dispatch(setOverlay(null));
						}
						else if (selectedItem.category === 'content')
						{
							const { tab_id, session_id } = getCurrentIds();
							socket.emit('take_action', {
								action_type: `${selectedItem.type}_detail`,
								id: selectedItem.id,
								[`${selectedItem.type}_id`]: selectedItem.id,
								session_id
							});
							anticipateDetail();
							store.dispatch(setOverlay(null));
						}
					}
				}
				break;
		}
	};

	return (
		<div
			className='w-[min(600px,90dvw)] bg-white rounded-lg shadow-lg'
			onClick={e => e.stopPropagation()}
			//onKeyDown={handleKeyDown}
			tabIndex={-1} // Make the div focusable
		>
			<div className='p-4 border-b'>
				<div className='relative'>
					<Search className='absolute left-[8px] top-[12px] text-[16px]' />
					<input
						ref={inputRef}
						placeholder='Look up...'
						className='pl-8 w-full rounded-md border border-neutral-200'
						value={inputValue}
						onChange={e =>
						{
							const newValue = e.target.value;
							setInputValue(newValue);

							if (inputTimer.current)
							{
								clearTimeout(inputTimer.current);
							}

							inputTimer.current = setTimeout(() =>
							{
								setSearchTerm(newValue);
							}, 300);
						}}
						onKeyDown={e =>
						{
							if (e.key === 'Escape')
							{
								store.dispatch(setOverlay(null));
							}
							else if (['ArrowDown', 'ArrowUp', 'Enter'].includes(e.key))
							{
								e.preventDefault();
								handleKeyDown(e);
							}
						}}
					/>
					{inputValue.length > 0 && (
						<button
							className='absolute right-[8px] top-[12px] text-neutral-500 hover:text-neutral-700 active:text-neutral-900 text-[20px]'
							onClick={() =>
							{
								if (inputTimer.current)
								{
									clearTimeout(inputTimer.current);
								}
								setInputValue('');
								setSearchTerm('');
							}}
						>
							<X className='h-4 w-4' />
						</button>
					)}
					{filterWarning && (
						<div className='absolute right-[32px] top-[12px] text-maven-indicator-500 text-[20px]'>
							<Tooltip delayDuration={0}>
								<TooltipTrigger asChild>
									<span className='text-maven-indicator-500'>
										<AlertTriangle className='h-4 w-4' />
									</span>
								</TooltipTrigger>
								<TooltipContent className={cn('', z_classes.overlay[2])}>
									<p>{filterWarning}</p>
								</TooltipContent>
							</Tooltip>
						</div>
					)}
				</div>
			</div>

			{searchTerm.length >= filterLengthThreshold && loadingEnum && (
				<div className='p-2 gap-6 text-sm text-neutral-500 flex flex-row items-center justify-center'>
					<p className='text-nowrap'>Loading {loadingEnum}...</p>
					<div
						className='h-1 w-full animate-loading-slider'
						style={{
							background: 'no-repeat linear-gradient(#339969 0 0), no-repeat linear-gradient(#339969 0 0), #d9f2e6',
							backgroundSize: '60% 100%'
						}}
					/>
				</div>
			)}

			<div className='h-[400px] overflow-y-auto'>
				<div className='p-4'>
					<SearchHint searchTerm={searchTerm} />

					{searchTerm.length >= filterLengthThreshold ? (
						<div className='flex flex-col gap-2'>
							<UnifiedNavigationSection
								title='Tools'
								items={filteredItems.tools}
								sectionKey='tools'
								sectionStates={sectionStates}
								setSectionStates={setSectionStates}
								searchTerm={searchTerm}
								focusedIndex={focusedIndex}
								visibleItemStartIndex={0}
							/>
							<UnifiedNavigationSection
								title='Recent Work'
								items={filteredItems.work}
								sectionKey='work'
								sectionStates={sectionStates}
								setSectionStates={setSectionStates}
								searchTerm={searchTerm}
								focusedIndex={focusedIndex}
								visibleItemStartIndex={filteredItems.tools?.length || 0}
							/>

							{filteredItems.work.length > 0 && <hr className='my-2' />}

							<UnifiedNavigationSection
								title='Atlas'
								items={filteredItems.content}
								sectionKey='content'
								sectionStates={sectionStates}
								setSectionStates={setSectionStates}
								searchTerm={searchTerm}
								focusedIndex={focusedIndex}
								visibleItemStartIndex={(filteredItems.tools?.length || 0) + (filteredItems.work?.length || 0)}
							/>
							<EmptyState searchTerm={searchTerm} filteredItems={filteredItems} />
						</div>
					) : (
						<EmptyState searchTerm={searchTerm} filteredItems={filteredItems} />
					)}
				</div>
			</div>

			{searchTerm.length === 0 && (
				<div className='p-1 pb-3 text-sm text-neutral-500 px-4 flex flex-row justify-end items-center w-full'>
					<button
						className='hover:underline hover:text-neutral-700 active:text-neutral-900'
						onClick={() =>
						{
							const { session_id } = getCurrentIds();

							store.dispatch(setOverlay(null));

							insertTab({
								id: 'profile_viewer',
								title: 'Profile Viewer',
								actions: [
									{
										id: 'profile_viewer',
										action_id: 'profile_viewer',
										action_type: 'profile_viewer',
										tab_id: 'profile_viewer',
										data: {
											entityType: 'drug'
										}
									}
								],
								session_id: session_id!,
								created_at: new Date().toISOString()
							});
						}}
					>
						Looking for the classic Profile Viewer?
					</button>
				</div>
			)}
		</div>
	);
};

const UnifiedNavigationTrigger = () =>
{
	return (
		<StartCard
			title='Look Up'
			description='Look up across our atlas content, your work, and more.'
			icon={<Search className='h-4 w-4' />}
			ctaText='Search'
			onClick={() => store.dispatch(setOverlay({ content: <UnifiedNavigationModal /> }))}
		/>
	);

	return (
		<button
			className='flex flex-col gap-2 p-4 items-start border rounded-md group bg-white w-full transition-all duration-300 hover:shadow-md hover:-translate-y-0.5 hover:border-maven-primary-500'
			onClick={() =>
			{
				store.dispatch(
					setOverlay({
						content: <UnifiedNavigationModal />,
						closeCallback: () => store.dispatch(setOverlay({ content: null }))
					})
				);
			}}
		>
			<p className='text-2xl font-bold'>Find Anything</p>
			<p className='text-sm text-neutral-500'>Search across our content (drugs, companies, trials, etc.), your work, and more.</p>
			<p className='p-2 border rounded-md w-full flex items-center gap-2 text-neutral-500'>
				<Search className='h-4 w-4' />
				<span>Search</span>
			</p>
		</button>
	);

	return (
		<Button
			variant='outline'
			className={cn(
				'flex items-center gap-2 border rounded-md group transition-all duration-300 hover:shadow-md hover:-translate-y-0.5 hover:border-maven-primary-500 bg-white'
			)}
			onClick={() =>
			{
				store.dispatch(
					setOverlay({
						content: <UnifiedNavigationModal />,
						onClose: () => store.dispatch(setOverlay({ content: null }))
					})
				);
			}}
		>
			<Search className='h-4 w-4' />
			<span>Search</span>
		</Button>
	);
};

const UnifiedNavigationSmallTrigger = () =>
{
	return (
		<NavIcon
			iconComponent={
				<div
					id='help-trigger'
					className='flex p-1 rounded-full overflow-visible cursor-pointer '
					onClick={e =>
					{
						store.dispatch(
							setOverlay({
								content: <UnifiedNavigationModal />,
								onClose: () => store.dispatch(setOverlay({ content: null }))
							})
						);
					}}
				>
					<Search className='' />
				</div>
			}
			bind='^k'
		/>
	);
};

export { UnifiedNavigationModal, UnifiedNavigationTrigger, UnifiedNavigationSmallTrigger };
