import { User } from '@propelauth/react';

import { setSessionData } from '@/redux/sessionSlice';
import store from '@/redux/store';
import { setActiveTabIndex } from '@/redux/tabSlice';

import Action from '@/types/Action';
import Session from '@/types/Session';
import Tab from '@/types/Tab';

import appendToTab from '../mutateSession/appendToTab';

const anticipateDetail = () =>
{
	const state = store.getState();
	const user: User | null = state.user.user;

	if (!user) return;

	const sessionData: Session | null = state.session.sessionData;

	if (!sessionData) return;

	const detailAction: Action[] = [
		{
			id: '-1',
			action_type: 'document_detail',
			data: null,
			action_id: '-1',
			tab_id: '-1',
			role_info: {
				display_name: `${user.firstName} ${user.lastName}`,
				type: 'user',
				user_id: user.userId,
				avatar_url: user.pictureUrl ?? ''
			}
		}
	];

	const newTab: Tab = {
		id: '-1',
		actions: detailAction,
		session_id: '-1',
		title: 'Detail',
		created_at: ''
	};

	const newTabs = [...sessionData.tabs, newTab];

	const newSessionData = {
		...sessionData,
		tabs: newTabs
	};

	store.dispatch(setSessionData(newSessionData));
	store.dispatch(setActiveTabIndex(newTabs.length - 1));
};

export default anticipateDetail;
