import { MutableRefObject, RefObject, useCallback, useEffect, useRef } from 'react';

const useMatchWidth = (): {
	triggerRef: any;
	contentRef: any;
	setWidth: () => void; // Include setWidth in the return type
} =>
{
	const triggerRef = useRef<HTMLElement | null>(null);
	const contentRef = useRef<HTMLElement | null>(null);

	const hasVerticalScrollbar = useCallback((element: HTMLElement | null): boolean =>
	{
		if (!element) return false;
		return element.scrollHeight > element.clientHeight;
	}, []);

	// Wrap setWidth in useCallback to ensure it has a stable identity
	// This prevents unnecessary re-renders or effect invocations if setWidth is used as a dependency
	const setWidth = useCallback((): void =>
	{
		const scrollbarWidth = 16; // Consider making this dynamic if needed.
		const hasVScroll = hasVerticalScrollbar(contentRef.current);

		if (triggerRef.current && contentRef.current)
		{
			contentRef.current.style.width = `${triggerRef.current.offsetWidth + (hasVScroll ? scrollbarWidth : 0)}px`;
		}
	}, [hasVerticalScrollbar]); // Depend on hasVerticalScrollbar

	useEffect(() =>
	{
		const observer = new MutationObserver(() =>
		{
			if (contentRef.current)
			{
				setWidth();

				const nestedObserver = new MutationObserver(() =>
				{
					setWidth();
				});

				nestedObserver.observe(contentRef.current, { childList: true, subtree: true });
			}
		});

		observer.observe(document.body, { childList: true });

		// Cleanup function to stop observing when the component unmounts.
		return () =>
		{
			observer.disconnect();
		};
	}, [setWidth]); // Depend on setWidth

	return { triggerRef, contentRef, setWidth };
};

const useMatchWidthById = (triggerId: string, contentSelector: string) =>
{
	const hasVerticalScrollbar = useCallback((element: HTMLElement | null): boolean =>
	{
		if (!element) return false;
		return element.scrollHeight > element.clientHeight;
	}, []);

	const width = useRef<number>(0);

	const setWidth = useCallback((): void =>
	{
		const scrollbarWidth = 16;
		const triggerEl = document.getElementById(triggerId);
		const contentEls = document.querySelectorAll(contentSelector);
		const hasVScroll = Array.from(contentEls).some(el => hasVerticalScrollbar(el as HTMLElement));

		if (triggerEl && contentEls.length > 0)
		{
			contentEls.forEach(contentEl =>
			{
				if (contentEl instanceof HTMLElement)
				{
					contentEl.style.width = `${triggerEl.offsetWidth + (hasVScroll ? scrollbarWidth : 0)}px`;
				}
			});
			width.current = triggerEl.offsetWidth + (hasVScroll ? scrollbarWidth : 0);
		}
	}, [triggerId, contentSelector, hasVerticalScrollbar]); // Depend on IDs and hasVerticalScrollbar

	useEffect(() =>
	{
		setWidth(); // Initial adjustment
		let nestedObserver: MutationObserver;
		const observer = new MutationObserver(() =>
		{
			setWidth();

			nestedObserver = new MutationObserver(() =>
			{
				setWidth();
			});

			document.querySelectorAll(contentSelector).forEach(contentEl =>
			{
				if (contentEl instanceof HTMLElement)
				{
					nestedObserver.observe(contentEl, { childList: true, subtree: true });
				}
			});
		});

		observer.observe(document.body, { childList: true, subtree: true });

		// Cleanup function to stop observing when the component unmounts.
		return () =>
		{
			observer.disconnect();
			if (nestedObserver) nestedObserver.disconnect();
		};
	}, [setWidth, contentSelector]); // Depend on setWidth and contentSelector

	// Return width and setWidth
	return { width, setWidth };
};

export { useMatchWidthById };
export default useMatchWidth;
