import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InteractionState
{
	deeplinks: any[];
	scroll: Record<string, any>;
	fieldFill: Record<string, any>;
	fieldFocus: Record<string, any>;
	fieldState: Record<string, any>;
	chatText: string;
}

const initialState: InteractionState = {
	deeplinks: [],
	scroll: {},
	fieldFill: {},
	fieldFocus: {},
	fieldState: {},
	chatText: ''
};

export const interactionSlice = createSlice({
	name: 'interaction',
	initialState,
	reducers: {
		setChatText: (state, action: PayloadAction<string>) =>
		{
			return { ...state, chatText: action.payload };
		},
		addDeeplink: (state, action: PayloadAction<any>) =>
		{
			return { ...state, deeplinks: [...state.deeplinks, action.payload] };
		},
		addScroll: (state, action: PayloadAction<any>) =>
		{
			return {
				...state,
				scroll: {
					...state.scroll,
					[action.payload.key]: action.payload.value
				}
			};
		},
		addFieldFill: (state, action: PayloadAction<any>) =>
		{
			return {
				...state,
				fieldFill: {
					...state.fieldFill,
					[action.payload.key]: action.payload.value
				}
			};
		},
		addFieldFocus: (state, action: PayloadAction<any>) =>
		{
			return {
				...state,
				fieldFocus: {
					...state.fieldFocus,
					[action.payload.key]: action.payload.value
				}
			};
		},
		addFieldState: (state, action: PayloadAction<any>) =>
		{
			return {
				...state,
				fieldState: {
					...state.fieldState,
					[action.payload.key]: action.payload.value
				}
			};
		},
		setDeeplinks: (state, action: PayloadAction<any[]>) =>
		{
			return { ...state, deeplinks: action.payload };
		},
		setScroll: (state, action: PayloadAction<any>) =>
		{
			return { ...state, scroll: action.payload };
		},
		setFieldFill: (state, action: PayloadAction<any>) =>
		{
			return { ...state, fieldFill: action.payload };
		},
		setFieldFocus: (state, action: PayloadAction<any>) =>
		{
			return { ...state, fieldFocus: action.payload };
		},
		setFieldState: (state, action: PayloadAction<any>) =>
		{
			return { ...state, fieldState: action.payload };
		}
	}
});

export const {
	setChatText,
	addDeeplink,
	addScroll,
	addFieldFill,
	addFieldFocus,
	addFieldState,
	setDeeplinks,
	setScroll,
	setFieldFill,
	setFieldFocus,
	setFieldState
} = interactionSlice.actions;
export default interactionSlice.reducer;
