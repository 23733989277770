import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ProfilingState
{
	//Initial load
	mountTime: number | null;
	startConnectionTime: number | null;
	connectionTime: number | null;
	sessionsFetchTime: number | null;
	sessionRetrievalTime: number | null;
	tabRetrievalTime: number | null;
	actualRenderTime: number | null;

	//Threshold slowdown logging
	awaitingKey: string | null | undefined;
	awaitingThreshold: number | null;
	startTime: number | null;
	pastThresholdAttach: any;
}

const initialState: ProfilingState = {
	mountTime: null,
	startConnectionTime: null,
	connectionTime: null,
	sessionsFetchTime: null,
	sessionRetrievalTime: null,
	tabRetrievalTime: null,
	actualRenderTime: null,

	awaitingKey: null,
	awaitingThreshold: null,
	startTime: null,
	pastThresholdAttach: null
};

const profilingSlice = createSlice({
	name: 'profiling',
	initialState,
	reducers: {
		setMountTime(state, action: PayloadAction<number>)
		{
			if (!state.mountTime)
			{
				const newState = { ...state, mountTime: action.payload };
				console.log('[Profiling] Mount time: ' + newState.mountTime + 'ms, returning new state', newState);
				return newState;
			}
		},
		setStartConnectionTime(state, action: PayloadAction<number>)
		{
			if (state.mountTime !== null)
			{
				const newState = { ...state, startConnectionTime: action.payload };
				console.log('[Profiling] Component mount to connection time: ' + (newState.startConnectionTime - state.mountTime) + 'ms');
				return newState;
			}
			return state;
		},
		setConnectionTime(state, action: PayloadAction<number>)
		{
			if (state.startConnectionTime !== null)
			{
				const newState = { ...state, connectionTime: action.payload };
				console.log('[Profiling] Connection time: ' + (newState.connectionTime - state.startConnectionTime) + 'ms');
				return newState;
			}
			return state;
		},
		setSessionsFetchTime(state, action: PayloadAction<number>)
		{
			if (state.connectionTime !== null && state.sessionsFetchTime === null)
			{
				const newState = { ...state, sessionsFetchTime: action.payload };
				console.log('[Profiling] Sessions fetch time: ' + (newState.sessionsFetchTime - state.connectionTime) + 'ms');
				return newState;
			}
			return state;
		},
		setSessionRetrievalTime(state, action: PayloadAction<number>)
		{
			if (state.sessionsFetchTime !== null)
			{
				const newState = { ...state, sessionRetrievalTime: action.payload };
				console.log('[Profiling] Session retrieval time: ' + (newState.sessionRetrievalTime - state.sessionsFetchTime) + 'ms');
				return newState;
			}
			return state;
		},
		setTabRetrievalTime(state, action: PayloadAction<number>)
		{
			if (state.sessionRetrievalTime !== null && state.tabRetrievalTime === null)
			{
				const newState = { ...state, tabRetrievalTime: action.payload };
				console.log('[Profiling] Tab retrieval time: ' + (newState.tabRetrievalTime - state.sessionRetrievalTime) + 'ms');
				return newState;
			}
			return state;
		},
		setActualRenderTime(state, action: PayloadAction<number>)
		{
			if (state.tabRetrievalTime !== null && state.actualRenderTime === null)
			{
				const newState = { ...state, actualRenderTime: action.payload };
				console.log('[Profiling] Actual render time: ' + (newState.actualRenderTime - state.tabRetrievalTime) + 'ms', 'returning', newState);
				return newState;
			}
			else
			{
				const newState = { ...state, actualRenderTime: action.payload };
				//console.log('Cant set render time with missing retrieval time', newState);
			}
			return state;
		},

		// Threshold slowdown logging
		setAwaitingKey(state, action: PayloadAction<string | null | undefined>)
		{
			return { ...state, awaitingKey: action.payload };
		},
		setAwaitingThreshold(state, action: PayloadAction<number | null>)
		{
			return { ...state, awaitingThreshold: action.payload };
		},
		setStartTime(state, action: PayloadAction<number | null>)
		{
			return { ...state, startTime: action.payload };
		},
		setPastThresholdAttach(state, action: PayloadAction<any>)
		{
			return { ...state, pastThresholdAttach: action.payload };
		}
	}
});

export const {
	setMountTime,
	setStartConnectionTime,
	setConnectionTime,
	setSessionsFetchTime,
	setSessionRetrievalTime,
	setTabRetrievalTime,
	setActualRenderTime,

	setAwaitingKey,
	setAwaitingThreshold,
	setStartTime,
	setPastThresholdAttach
} = profilingSlice.actions;

export default profilingSlice.reducer;
