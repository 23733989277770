import { micromark } from 'micromark';
import { gfm, gfmHtml } from 'micromark-extension-gfm';
import { rehype } from 'rehype';
import rehypeDocument from 'rehype-document';
import rehypeFormat from 'rehype-format';
import rehypeSanitize from 'rehype-sanitize';
import rehypeStringify from 'rehype-stringify';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { unified } from 'unified';

import { cn } from './lib/utils';

interface MarkdownConversionOptions
{
	//Whether to show a cursor at the end of the markdown.
	showCursor: boolean;

	//Use GFM extensions
	gfm: boolean;

	//Will this text contain tables?
	tables?: boolean;

	//Highlight
	highlight: boolean;

	//Citations
	citations: boolean;

	//Code blocks
	codeblocks: boolean;

	//Spacing on h1/h2/h3
	spacing: boolean;

	size: 'xs' | 's' | 'm' | 'l';
}

/*const defaultOptions: MarkdownConversionOptions = {
	showCursor: false,
	gfm: false,
	highlight: true,
	citations: true,
	codeblocks: false,
};*/

//For now, we're going to use the default options as on for everything, because it's more than fast enough.
const defaultOptions: MarkdownConversionOptions = {
	showCursor: false,
	gfm: true,
	highlight: true,
	citations: true,
	codeblocks: true,
	tables: true,
	spacing: true,
	size: 'm'
};

function markdownToHtml(markdown: string, passedOptions?: Partial<MarkdownConversionOptions>)
{
	if (!markdown || typeof markdown !== 'string') return '';

	const options = { ...defaultOptions, ...passedOptions };

	const extensions = options.gfm
		? {
				extensions: [gfm()],
				htmlExtensions: [gfmHtml()]
			}
		: {};

	let result = micromark(markdown, extensions);

	//console.log(result, 'from', markdown);

	let rules = [
		//General rules for handling conversion md -> tailwind

		//Double Linebreak:
		{ pattern: /<br><strong>/g, replacement: '<br/><br/><strong>' },

		//Paragraph breaks between <p> tags:
		{ pattern: /<\/p>\s*<p>/g, replacement: '</p><br/><p>' },

		{ pattern: /<a/g, replacement: "<a class='text-blue-400 visited:text-maven-accent-600 underline [overflow-wrap:anywhere] ' target='_blank' " },

		//Lists
		{ pattern: /<ul/g, replacement: "<ul class='py-2 list-disc '" },
		{ pattern: /<ol/g, replacement: "<ol class='py-2 list-decimal'" },
		{ pattern: /<li>/g, replacement: "<li class='ml-6'><p>" }, // this is a bit hacky, wrap all li content in a p tag, so the cursor can be added to the last one.
		{ pattern: /<\/li>/g, replacement: '</p></li>' }
	];

	const sizeClasses = {
		xs: {
			h1: 'text-lg',
			h2: 'text-base',
			h3: 'text-sm'
		},
		s: {
			h1: 'text-xl',
			h2: 'text-lg',
			h3: 'text-base'
		},
		m: {
			h1: 'text-2xl',
			h2: 'text-xl',
			h3: 'text-lg'
		},
		l: {
			h1: 'text-3xl',
			h2: 'text-2xl',
			h3: 'text-xl'
		}
	};

	const sizeClass = sizeClasses[options.size] || sizeClasses.m;

	rules = rules.concat([
		// Headers/Links
		{ pattern: /<h1>/g, replacement: `<h1 class='${sizeClass.h1} py-3 font-bold'>` },
		{ pattern: /<\/h1>/g, replacement: `</h1>` },
		{ pattern: /<h2>/g, replacement: `<h2 class='${sizeClass.h2} py-2 font-bold'>` },
		{ pattern: /<\/h2>/g, replacement: `</h2>` },
		{ pattern: /<h3>/g, replacement: `<h3 class='${sizeClass.h3} py-2 font-bold'>` },
		{ pattern: /<\/h3>/g, replacement: `</h3>` },
		{ pattern: /<hr/g, replacement: `<hr class='my-2'` }
	]);

	if (options.tables)
	{
		rules = rules.concat([
			//Tables
			{
				pattern: /<table/g,
				replacement:
					"<div class=' w-full max-w-[98dvw] md:max-w-[20rem] md:@md:max-w-[26rem] md:@lg:max-w-[30rem] md:@xl:max-w-[34rem] md:@2xl:max-w-[40rem] md:@3xl:max-w-[46rem] md:@4xl:max-w-[52rem] md:@6xl:max-w-[100ch] overflow-x-auto text-nowrap p-2 '><table class=' table-auto '"
			},
			{ pattern: /<\/table>/g, replacement: '</table></div>' },

			{ pattern: /<thead/g, replacement: "<thead class=''" },
			{ pattern: /<th>/g, replacement: "<th class='border border-neutral-200 p-1'" },
			{ pattern: /<td/g, replacement: "<td class='border border-neutral-200 p-1'" },
			{ pattern: /<hr>/g, replacement: "<hr class='mt-2 pb-2'>" }
		]);
	}

	// Apply markdown
	rules.forEach(rule =>
	{
		result = result.replace(rule.pattern, rule.replacement);
	});

	//console.log(result);

	if (options.showCursor)
	{
		//Apply a special rule for the last <p> tag, to draw an in-progress cursor type beat.
		const lastPIndex = result.lastIndexOf('<p>');

		let replacementSlice = result.slice(lastPIndex);
		replacementSlice = replacementSlice.replace('<p>', "<p class=''>");
		replacementSlice = replacementSlice.replace(
			'</p>',
			"<span id='message-cursor'  class='font-bold text-lg transition-none animate-cursor-flash'> |</span></p>"
		);

		result = result.slice(0, lastPIndex) + replacementSlice;
	}

	//Apply in-text citations of the format {i}
	if (options.citations)
	{
		const inTextCitationRegex = /\{(\d+)\}/g;
		result = result.replace(inTextCitationRegex, (match, group1) =>
		{
			return `<sup class='text-maven-accent-300 hover:text-maven-accent-400 underline cursor-pointer mx-0.5 h-8 w-8' data-inline-citation-${group1}>[${group1}]</sup>`;
		});
	}

	if (options.highlight)
	{
		//Allow highlighting text, since remark's markdown parser doesn't support it.
		result = result.replace(/@@&gt;/g, "<span class='markdown-highlighting bg-maven-highlight'>");
		result = result.replace(/@@/g, '</span>');
	}

	//Allow in-line MUI icons via <@mui>icon_name</@mui>
	result = result.replace(/@mui(.*?)\/@mui/g, "<span class='material-icons align-text-bottom'>$1</span>");

	if (options.codeblocks)
	{
		//Replace code blocks that are breaking the formatting
		result = result.replace(/<pre><code/g, "<p class='text-wrap break-words font-normal text-base'");
		result = result.replace(/\/code><\/pre>/g, '/p>');
	}

	return result;
}

function markdownToComponent(markdown: string, passedOptions?: Partial<MarkdownConversionOptions>)
{
	const html = markdownToHtml(markdown, passedOptions);

	const outerSizingClasses = {
		none: '',
		xs: 'text-xs',
		s: 'text-sm',
		m: 'text-base',
		l: 'text-lg'
	};
	const outerSizingClass = outerSizingClasses[passedOptions?.size || 'none'];

	//XSS? Never heard of it.
	const comp = <div className={cn('flex flex-col [overflow-wrap:anywhere]', outerSizingClass)} dangerouslySetInnerHTML={{ __html: html }} />;

	return comp;
}

export { markdownToComponent, markdownToHtml };
