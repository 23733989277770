import BitSet from 'bitset';

import { useReducer, useState } from 'react';

function useStatefulBitset(initialSelection?: number[])
{
	const [selection, setSelection] = useState<BitSet>(new BitSet(initialSelection ?? []));
	const [updateDependency, forceUpdate] = useReducer(x => x + 1, 0);

	const addSelection = (indices: number[]) =>
	{
		indices.forEach(index => selection.set(index));
		forceUpdate();
	};

	const removeSelection = (indices: number[]) =>
	{
		indices.forEach(index => selection.clear(index));
		forceUpdate();
	};

	return {
		bitsetSelection: selection,
		setBitsetSelection: setSelection,
		addBitsetSelection: addSelection,
		removeBitsetSelection: removeSelection,
		updateDependency
	};
}

export default useStatefulBitset;
