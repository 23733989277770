import { usePostHog } from 'posthog-js/react';
import { io } from 'socket.io-client';

declare global
{
	var socket: any;
	var socketLog: any[];
	var posthog: any;
}

const getSocket = () =>
{
	//If a socket already exists, return it
	if (globalThis.socket)
	{
		return globalThis.socket;
	}

	globalThis.socketLog = [];

	//Otherwise, connect and return the new socket
	const socket = io(process.env.NEXT_PUBLIC_BACKEND_URL + '/session', {
		reconnection: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax: 5000,
		reconnectionAttempts: Infinity,
		path: '/socket.io',
		timeout: 5000,
		ackTimeout: 5000
	});

	//Put a wrapper around default emit function to log all emits
	const emit = socket.emit;
	socket.emit = function (event, body)
	{
		console.log('Emitting: ', event, body);
		emit.call(socket, event, body);

		//Add posthog logging for certain event types
		const captureTypes = ['take_action', 'open_session'];

		if (captureTypes.includes(event))
		{
			//console.log('Capturing event on Posthog: ', event, body);
			const posthog = globalThis.posthog;
			if (posthog) posthog.capture(event, body);
		}
		else
		{
			//console.log('Not capturing event on Posthog: ', event, body);
		}

		return socket;
	};

	globalThis.socket = socket;
	return socket;
};

export default getSocket;
