/*
Managing z-indices throughout the app can be a challenge.
Challenging components:
- Html containers in R3F context have an extremely high z-index range by default
- Our Overlay makes it difficult 
- Dropdowns and nested dropdowns particularly make it hard
*/

const z = {
	sidebar: 40000,
	overlay_base: 3000,
	r3f_start: 199,
	r3f_end: 100
};

//Tailwind only indexes classes that appear fully in source code, so they need to be matched here.
//To use this, choose the key for what we want to be *above*, and then add the classes in order of priority.
const z_classes = {
	sidebar: ['z-[40000]', 'z-[40001]', 'z-[40002]', 'z-[40003]', 'z-[40004]', 'z-[40005]'],
	overlay: ['z-[3000]', 'z-[3001]', 'z-[3002]', 'z-[3003]', 'z-[3004]', 'z-[3005]'],
	r3f: ['z-[200]', 'z-[201]', 'z-[202]', 'z-[203]', 'z-[204]', 'z-[205]']
};

export { z, z_classes };
