import { configureStore } from '@reduxjs/toolkit';

import cacheReducer from './cacheSlice';
import enumReducer from './enumsSlice';
import chatReducer from './focusSlice';
import interactionReducer from './interactionSlice';
import layoutReducer from './layoutSlice';
import notificationsReducer from './notificationsSlice';
import performanceReducer from './performanceSlice';
import sessionReducer from './sessionSlice';
import supportReducer from './supportSlice';
import tabReducer from './tabSlice';
import userReducer from './userSlice';

export const store = configureStore({
	reducer: {
		session: sessionReducer,
		tab: tabReducer,
		user: userReducer,
		enums: enumReducer,
		layout: layoutReducer,
		interaction: interactionReducer,
		focus: chatReducer,
		support: supportReducer,
		cache: cacheReducer,
		performance: performanceReducer,
		notifications: notificationsReducer
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false
		})
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
