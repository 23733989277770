// focusSlice.ts
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

interface FocusWithId
{
	id: string;
	title: string;
	type?: string;
}

interface FocusState
{
	deepResearch: boolean;
	focusSelection: string;
	items: FocusWithId[];
	tab: FocusWithId;
	company: string[];
	drug: string[];
	documentType: string[];
	startDate: string;
	endDate: string;
}

const initialState: FocusState = {
	deepResearch: false,
	focusSelection: 'focus',
	items: [],
	tab: { id: '', title: 'All Tabs' },
	company: ['Any Company'],
	drug: ['Any Drug'],
	documentType: ['Any Document Type'],
	startDate: '01/01/2010',
	endDate: format(new Date(), 'MM/dd/yyyy')
};

export const focusSlice = createSlice({
	name: 'focus',
	initialState,
	reducers: {
		setDeepResearch: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, deepResearch: action.payload };
		},
		setFocusSelection: (state, action: PayloadAction<string>) =>
		{
			return { ...state, focusSelection: action.payload };
		},
		setItems: (state, action: PayloadAction<FocusWithId[]>) =>
		{
			return { ...state, items: action.payload };
		},
		setTab: (state, action: PayloadAction<FocusWithId>) =>
		{
			return { ...state, tab: action.payload };
		},
		setCompany: (state, action: PayloadAction<string[]>) =>
		{
			return { ...state, company: action.payload };
		},
		setDrug: (state, action: PayloadAction<string[]>) =>
		{
			return { ...state, drug: action.payload };
		},
		setDocumentType: (state, action: PayloadAction<string[]>) =>
		{
			return { ...state, documentType: action.payload };
		},
		setStartDate: (state, action: PayloadAction<string>) =>
		{
			return { ...state, startDate: action.payload };
		},
		setEndDate: (state, action: PayloadAction<string>) =>
		{
			return { ...state, endDate: action.payload };
		}
	}
});

export const { setDeepResearch, setFocusSelection, setTab, setDrug, setCompany, setDocumentType, setStartDate, setEndDate, setItems } = focusSlice.actions;
export type { FocusState };
export default focusSlice.reducer;
