/*
When the user clicks new chat, we don't want any latency.
To avoid back-and-forth in this case, the client will just create a new empty chat server-side and then update the session when a meaningful action is recieved.
*/
import getSocket from '@/globals/socketConfig';
import { format } from 'date-fns';

import { resetFocusFilters } from '@/components/Layout/Focus';

import getUUID from '@/utils/getUUID';

import { setMainPanelCollapsed, setRightSidebarCollapsed } from '@/redux/layoutSlice';
import { setSessionData } from '@/redux/sessionSlice';
import store from '@/redux/store';
import { setActiveActionIndex, setActiveChatIndex, setBlocking, setMaxActionIndex } from '@/redux/tabSlice';

import anticipateMessageAndResponse from '../anticipateRender/messageAndResponse';

function newChat()
{
	console.log('Trying to create new chat');
	const state = store.getState();
	const session = state.session.sessionData;
	const socket = getSocket();

	if (!session) return;

	let newSessionData = { ...session };

	const newChatId = getUUID();
	//console.log(newChatId);

	//Unfortunately, can't use the chat constructor, since redux requires a serializable object.
	const newChatObject = {
		action_type: 'new_chat',
		session_id: session.id,
		id: newChatId,
		chat_id: newChatId,
		created_at: Date.now().toString(),
		chat_title: 'New Chat',
		actions: []
	};

	let newChats = [newChatObject, ...newSessionData.chats] as Array<Object>;

	console.log('Creating new chat', newChatObject);

	socket.emit('take_action', newChatObject);

	newSessionData.chats = newChats as any;
	console.log('Updated session data: ', newSessionData);

	store.dispatch(setSessionData(newSessionData));

	//Set active chat to the new chat
	store.dispatch(setActiveChatIndex(0));

	resetFocusFilters();

	return newChatObject;
}

function newChatWithMessage(message: string)
{
	message = message.trim();

	if (message.length === 0)
	{
		return;
	}

	console.log('Sending message:', message, ' on new chat');

	//Extract entity restrictions, limit to 25

	const state = store.getState();
	const session = state.session.sessionData!;
	const company_restriction_names = state.focus.company?.filter(entity => entity !== 'Any Company').slice(0, 25) ?? [];
	const company_restrictions =
		state.enums.enums.companies?.filter((company: { id: string; name: string }) => company_restriction_names.includes(company.name)) ?? [];
	const drug_restriction_names = state.focus.drug?.filter(entity => entity !== 'Any Drug').slice(0, 25) ?? [];
	const drug_restrictions = state.enums.enums.drugs?.filter((drug: { id: string; name: string }) => drug_restriction_names.includes(drug.name)) ?? [];

	//Limit to 25 entities
	const entity_restrictions = [...company_restrictions, ...drug_restrictions].slice(0, 25);

	const newChatId = getUUID();
	const newChatObject = {
		action_type: 'new_chat_message',
		session_id: session.id,
		id: newChatId,
		chat_id: newChatId,
		created_at: Date.now().toString(),
		chat_title: 'New Chat',
		actions: [],
		message,

		//If there's focus filters applied, send them along with the message
		focus:
			state.focus.focusSelection === 'focus'
				? {
						type: 'filter',
						filters: {
							start_date: format(state.focus.startDate, 'MM-dd-yyyy'),
							end_date: format(state.focus.endDate, 'MM-dd-yyyy'),
							document_type: state.focus.documentType,
							entity_restrictions: entity_restrictions,
							reference_tabs: state.focus.tab.id ? [state.focus.tab.id] : []
						}
					}
				: {
						type: 'items',
						items: state.focus.items.map(entity => ({
							...entity,
							entity_id: entity.id
						}))
					}
	};

	if (!session) return;

	let newSessionData = { ...session };

	let newChats = [newChatObject, ...newSessionData.chats] as Array<Object>;
	newSessionData.chats = newChats as any;

	console.log('Updated session data: ', newSessionData);

	store.dispatch(setSessionData(newSessionData));

	socket.emit('take_action', newChatObject);

	const activeTabIndex = state.tab.activeTabIndex;
	let activeChatIndex = state.tab.activeChatIndex + 1;

	if (session)
	{
		//Update the tab state based on the message.

		//Right panel should definitely be visible if there are actions in the tab.
		if (session.tabs[activeTabIndex].actions.length === 0)
		{
			store.dispatch(setRightSidebarCollapsed(false));
		}

		//If all the tabs have no actions, then the right side panel should fully expand.
		if (session.tabs.every(tab => tab.actions.length === 0))
		{
			store.dispatch(setMainPanelCollapsed(true));
		}

		anticipateMessageAndResponse(message);
	}

	//While a message is generating, block future actions
	store.dispatch(setBlocking({ blocking: true, reason: 'generating' }));
}

export default newChat;
export { newChatWithMessage };
