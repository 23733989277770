export default function formatName(name: string)
{
	if (!name) return '-';

	if (typeof name !== 'string') return name;

	return (
		name
			// Replace underscores with spaces
			.replace(/_/g, ' ')
			// Capitalize the first letter of each word
			.replace(/\b(\w)/g, char => char.toUpperCase())
			.replace("'S", "'s")
			.replace(/(\b)*sec(\b)+/gi, 'SEC')
			.replace('Fda ', 'FDA ')
			.replace(/Csv\b/, 'CSV')
	);
}
