import store from '@/redux/store';

function getCurrentIds()
{
	const state = store.getState();
	const sessionData = state.session.sessionData;
	const tab = sessionData?.tabs[state.tab.activeTabIndex];

	return {
		session_id: sessionData?.id,
		tab_id: tab?.id
	};
}

export default getCurrentIds;
