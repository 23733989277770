function formatNumber(num: number)
{
	if (typeof num !== 'number')
	{
		console.error('formatNumber: num is not a number', num);
		return '';
	}

	if (num >= 1e9)
	{
		// Format as billions
		return (num / 1e9).toFixed(1) + 'b';
	}
	else if (num >= 1e6)
	{
		// Format as millions
		return (num / 1e6).toFixed(1) + 'm';
	}
	else if (Number.isInteger(num))
	{
		// Format as an integer
		return num.toString();
	}
	else
	{
		// Format as a floating point
		return num.toFixed(2);
	}
}

export default formatNumber;
