import { setSessionData } from '@/redux/sessionSlice';
import store from '@/redux/store';
import { setActiveTabIndex } from '@/redux/tabSlice';

import Session from '@/types/Session';
import Tab from '@/types/Tab';

import getCurrentIds from '../getCurrentIds';

const insertTab = (data: Tab, initialRetrieval?: boolean) =>
{
	const state = store.getState();
	const sessionData: Session | null = state.session.sessionData;
	const activeTabIndex = state.tab.activeTabIndex;

	if (!sessionData)
	{
		console.error('[insertTab] No session data to insert tab into.');
		return;
	}

	let newTabIndex = sessionData.tabs.findIndex(tab => tab.id === data.id);

	const anyLoaded = activeTabIndex > -1;
	const tabInRange = sessionData.tabs && activeTabIndex < sessionData.tabs.length;
	const tabBlank = sessionData.tabs[activeTabIndex].actions?.length === 0 || sessionData.tabs[activeTabIndex].id === '-1';

	const overwrite = anyLoaded && tabInRange && tabBlank && !initialRetrieval;

	//If the current tab is blank, then it needs to be overwritten with the results.
	if (overwrite)
	{
		console.log('[insertTab] Overwriting blank tab with new data', data);
		newTabIndex = activeTabIndex;

		//If we're overwriting the current tab, then we should emit a deletion event for the current tab
		const { session_id, tab_id } = getCurrentIds();
		socket.emit('take_action', {
			action_type: 'delete_tab',
			session_id: session_id,
			tab_id: tab_id
		});
	}
	else
	{
		console.log('[insertTab] Inserting new tab, as current tab is not blank', data, 'at', activeTabIndex, anyLoaded, tabInRange, tabBlank);
	}

	//If this is a new tab, insert it at the end of the list
	if (newTabIndex === -1)
	{
		console.log('[insertTab] Inserting new tab at end of list', data);

		const newSessionData = { ...sessionData };
		const newTabs = [...newSessionData.tabs];
		newTabs.push(data);
		newSessionData.tabs = newTabs;

		store.dispatch(setSessionData(newSessionData));

		if (!initialRetrieval)
		{
			//We'll also need to update the active tab index
			store.dispatch(setActiveTabIndex(newTabs.length - 1));
		}
	}
	else
	{
		console.log('[insertTab] Inserting tab at index:', newTabIndex, data);

		const newSessionData = { ...sessionData };
		const newTabs = [...newSessionData.tabs];
		newTabs[newTabIndex] = data;
		newSessionData.tabs = newTabs;

		store.dispatch(setSessionData(newSessionData));

		if (!initialRetrieval)
		{
			//We'll also need to update the active tab index
			store.dispatch(setActiveTabIndex(newTabIndex));
		}
	}
};

export default insertTab;
