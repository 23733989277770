import { cn } from '@/utils/lib/utils';
import { ArrowRight } from '@/utils/lucide';

interface StartCardProps
{
	title: string;
	description: string;
	icon: React.ReactNode;
	ctaText: string;
	onClick: () => void;
	className?: string;
}

const StartCard = ({ title, description, icon, ctaText, onClick, className }: StartCardProps) =>
{
	return (
		<button
			className={cn(
				'flex flex-col items-start border rounded-md group transition-all duration-300 hover:shadow-md hover:-translate-y-0.5 hover:border-maven-primary-500 bg-white gap-2 justify-between min-h-[120px]',
				className
			)}
			onClick={onClick}
		>
			<div className='flex flex-col items-start gap-2 p-4'>
				<div className='flex flex-row items-center gap-3'>
					<p className='text-icon'>{icon}</p>
					<p className='text-xl font-bold'>{title}</p>
				</div>
				<p className='text-md text-neutral-500 text-start '>{description}</p>
			</div>

			<p className='hidden //flex flex-row items-center gap-3 text-sm font-medium group-hover:underline w-full p-4 py-2'>
				{ctaText}
				<ArrowRight />
			</p>
		</button>
	);
};

export default StartCard;
